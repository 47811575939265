import { FaqDto, getFaqPrivateEndpoint } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<FaqDto>({
    contentName: 'faq',
    contentEndpoint: getFaqPrivateEndpoint,
});

export default reducer;
export const fetchFaq = fetchContent;
