import React, { useState } from 'react';
import { ContactDetailsCheckForm } from './ui';
import { useCmsContent } from 'utils/useCmsContent';
import { parseErrorResponse } from '@cp-shared-7/frontend-integration';
import {
    ContactDetailsChange,
    ContactDetailsCheckContent,
    getChangeContactDetailsOnRegistrationEndpoint,
    OpenVerificationError,
    OpenVerificationInfo,
} from 'common';
import { CpDataApi } from 'cp-xhr';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { RegistrationLoadingPlaceholder, Spinner, useAuthentication } from '@cp-shared-7/frontend-ui';
import { useTranslation } from 'react-i18next';
import { idpHint } from '../../../config';
import { ContactDetailsCheckAction } from './types';

export type ContactDetailsCheckProps = {
    openVerificationInfo: OpenVerificationInfo;
    handleSmsRedirect: () => void;
};

const trimPlusPrefix = (mobileNumberCountryCode: string): string => {
    return mobileNumberCountryCode.replace(/\+/g, '');
};

type RequestProps = {
    isLoading: boolean;
    errorCode?: string;
};

const ContactDetailsCheckWithHandlers = withLoadingAndNoConnectionHandler(ContactDetailsCheckForm);

export const ContactDetailsCheck: React.FC<ContactDetailsCheckProps> = ({
    openVerificationInfo,
    handleSmsRedirect,
}) => {
    const [openVerificationRequest, setOpenVerificationRequest] = useState<RequestProps>({ isLoading: false });
    const [content, isLoading, cmsError] = useCmsContent<ContactDetailsCheckContent>('contact-details-check');

    const { login } = useAuthentication();
    const { i18n } = useTranslation();
    const language = i18n.languages[0];

    if (!content) return null;

    function redirectToIdentityKit(email: string): Promise<void> {
        return login({
            locale: language,
            loginHint: email,
            idpHint,
            prompt: 'login',
        });
    }

    const handleSubmit = (formValues: OpenVerificationInfo, action: ContactDetailsCheckAction): void => {
        if (action === 'LOGIN' && formValues.inviteEmail) {
            redirectToIdentityKit(formValues.inviteEmail);
        } else if (action === 'CONTINUE') {
            let emailAddress: string;
            let isRegistrationFlow: boolean;
            if (formValues.SFmail && formValues.SFmail === formValues.fsIdEmail) {
                emailAddress = formValues.SFmail;
                isRegistrationFlow = false;
            } else if (formValues.fsIdEmail && formValues.SFmail !== formValues.fsIdEmail) {
                emailAddress = formValues.fsIdEmail;
                isRegistrationFlow = true;
            } else {
                redirectToIdentityKit(formValues.SFmail || '');
                return;
            }

            const preparedChangeValues: ContactDetailsChange = {
                emailAddress,
                mobilePhoneCountryCode: `+${formValues.mobileNumberCountryCode}`,
                mobilePhone: formValues.mobileNumber.replace(/\s/g, ''),
                isRegistrationFlow,
            };

            setOpenVerificationRequest({ isLoading: true });
            CpDataApi.post(
                getChangeContactDetailsOnRegistrationEndpoint(openVerificationInfo.encryptedCPId || ''),
                preparedChangeValues,
            )
                .then(() => {
                    setOpenVerificationRequest({ isLoading: false });
                    handleSmsRedirect();
                })
                .catch((error) => {
                    setOpenVerificationRequest({
                        isLoading: false,
                        errorCode: parseErrorResponse<OpenVerificationError>(error)?.code,
                    });
                });
        } else {
            redirectToIdentityKit(formValues.SFmail || '');
        }
    };

    return (
        <>
            {openVerificationRequest.isLoading && <Spinner fullPage center />}
            <ContactDetailsCheckWithHandlers
                hasError={!!cmsError}
                isLoading={isLoading}
                content={content}
                formValues={{
                    ...openVerificationInfo,
                    mobileNumberCountryCode: trimPlusPrefix(openVerificationInfo.mobileNumberCountryCode),
                }}
                onSubmit={handleSubmit}
                submitError={openVerificationRequest.errorCode}
                loadingPlaceholder={<RegistrationLoadingPlaceholder />}
            />
        </>
    );
};
