import { FinancialDetails } from 'common';
import { DefaultMarketApiErrors } from '@cp-shared-7/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-7/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<FinancialDetails, DefaultMarketApiErrors>({
    dataName: 'financialDetails',
    fetchCallback: (link: string) => {
        return CpDataApi.get(link).then((response) => response.data);
    },
});

export default reducer;
export const fetchFinancialDetails = fetchData;
