import { Contract, formatAsCurrency, formatAsDate } from 'common';
import { ContractHeader, DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-7/frontend-ui';
import { Button, Layout, LicensePlate } from '@vwfs-bronson/bronson-react';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type UnpaidBalanceProps = {
    contract?: Contract;
};

export const UnpaidBalanceDetailsUi: React.FC<UnpaidBalanceProps> = ({ contract }) => {
    const { t } = useTranslation('unpaid-balance');
    const history = useHistory();

    if (!contract) return <NoConnectionNotification />;

    const { carBrand, carModel, productType, contractNumber, licensePlate, unpaidBalance } = contract;

    const carInformation = `${carBrand || ''} ${carModel || ''}`;
    const displayAdditionalInfo = !!(
        unpaidBalance?.nextDueDate ||
        unpaidBalance?.paymentDetailsEntity ||
        unpaidBalance?.paymentDetailsReference
    );

    const detailsFullLayout = displayAdditionalInfo ? '1/2' : '1/1';

    const handleClick = () => history.push('/dashboard');
    const paymentDetailsItems: DefinitionListItem[] = [
        {
            label: t('pending-installments'),
            value: unpaidBalance?.pendingInstallments,
        },
        {
            label: t('nominal-amount'),
            value: formatAsCurrency(unpaidBalance?.nominalAmount),
        },
        {
            label: t('arrears-amount'),
            value: formatAsCurrency(unpaidBalance?.arrearsAmount) || '€ 0',
        },
        {
            label: t('interest-on-arrears'),
            value: formatAsCurrency(unpaidBalance?.interestOnArrears),
            tooltip: t('interest-on-arrears-tooltip'),
        },
        {
            label: t('total-unpaid-amount'),
            value: formatAsCurrency(unpaidBalance?.totalUnpaidAmount),
        },
    ].filter((item) => item.value);

    const paymentAdditionalItems: DefinitionListItem[] = [
        {
            label: t('next-due-date'),
            value: formatAsDate(unpaidBalance?.nextDueDate),
        },
        {
            label: t('payment-entity'),
            value: unpaidBalance?.paymentDetailsEntity,
        },
        {
            label: t('payment-reference'),
            value: unpaidBalance?.paymentDetailsReference,
            tooltip: t('payment-reference-tooltip'),
        },
    ].filter((item) => item.value);

    return (
        <>
            <DataOverview>
                <ContractHeader
                    carInformation={carInformation || ''}
                    contractCategory={productType || ''}
                    contractNumber={contractNumber}
                    contractIdentifier={
                        <LicensePlate
                            size={'medium'}
                            country={'NL'}
                            countryCode={'NL'}
                            nl={true}
                            registrationNumber={licensePlate || '-'}
                            horizontalStripEu={true}
                            euStars={true}
                        />
                    }
                />
            </DataOverview>
            <br />
            <Layout>
                <Layout.Item default={detailsFullLayout} s="1/1">
                    <DataOverview title={t('sub-headline-left')}>
                        <DefinitionListHorizontal list={paymentDetailsItems} />
                    </DataOverview>
                </Layout.Item>
                <Layout.Item default="1/2" s="1/1">
                    {displayAdditionalInfo && (
                        <DataOverview title={t('sub-headline-right')}>
                            <DefinitionListHorizontal list={paymentAdditionalItems} />
                        </DataOverview>
                    )}
                </Layout.Item>
            </Layout>
            <Button secondary className="u-bg-white u-mt" onClick={handleClick}>
                {t('back-button')}
            </Button>
        </>
    );
};
