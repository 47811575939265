import { ContractsCms } from 'common';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { ContractHandover } from 'components/contracts/forms/contract-handover';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useCmsContent } from 'utils/useCmsContent';

export const ContractHandoverPage: React.FC = () => {
    const { contractNumber } = useParams<{ contractNumber: string }>();
    const [cmsContent] = useCmsContent<ContractsCms>('contracts');

    return (
        <>
            <ContentSection pageWrapSize="medium">
                <Heading level={1}>{cmsContent?.contractHandover.headline}</Heading>
                <p dangerouslySetInnerHTML={{ __html: cmsContent?.contractHandover.description || '' }} />
                <ContractHandover contractNumber={contractNumber} />
            </ContentSection>
        </>
    );
};
