import { Contract, DashboardMarketingCard, getBrokerDetailsEndpoint } from 'common';
import { useGetContractBasedApiData } from '@cp-shared-7/frontend-integration';
import { MarketingCardLoadingPlaceholder } from '@cp-shared-7/frontend-ui';
import { withLoadingHandler } from 'components/integration-wrapper';
import React from 'react';
import { BrokerDetailsDataSelector } from '../BrokerDetailsSelector';
import { fetchBrokerDetails } from '../BrokerDetailsSlice';
import { DashboardMarketingCardUi } from '../ui';

const DashboardMarketingCardWithHandlers = withLoadingHandler(DashboardMarketingCardUi);

export type DashboardMarketingCardContractProps = {
    mostRecentContract?: Contract;
    dashboardMarketingCard: DashboardMarketingCard;
};

export const DashboardMarketingCardWithContract: React.FC<DashboardMarketingCardContractProps> = ({
    mostRecentContract,
    dashboardMarketingCard,
}) => {
    const { data: brokerDetails, isLoading } = useGetContractBasedApiData(
        mostRecentContract?.contractNumber || '',
        fetchBrokerDetails,
        BrokerDetailsDataSelector,
        getBrokerDetailsEndpoint(mostRecentContract?.brokerBusinessPartnerId || ''),
        false,
    );

    return (
        <DashboardMarketingCardWithHandlers
            isLoading={isLoading}
            dashboardMarketingCard={dashboardMarketingCard}
            brokerDetails={brokerDetails}
            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
