import { Contract, ContractsCms } from 'common';
import { Card } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { ContractCardBody } from './Body';
import { ContractActionItems } from './ContractActionItems';
import { ContractCardHeader } from './Header';
import { ContractUnpaidBalance } from './UnpaidBalance/ContractUnpaidBalance';

export type ContractCardProps = {
    contract: Contract;
    content?: ContractsCms;
    defaultExpanded?: boolean;
};

export const ContractCard: React.FC<ContractCardProps> = ({ contract, content, defaultExpanded }) => {
    const { productType, contractNumber } = contract;
    return (
        <Card
            expandable
            title={`${productType || ''} ${contractNumber || ''}`}
            subtitle={``}
            className={'u-mb'}
            defaultExpanded={defaultExpanded}
            contentLarge={<ContractCardBody contract={contract} />}
        >
            <ContractCardHeader contract={contract} />
            <ContractUnpaidBalance contract={contract} content={content} />
            <ContractActionItems contract={contract} content={content} />
        </Card>
    );
};
