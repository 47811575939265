import { MyRequest } from 'common';
import * as Yup from 'yup';

const maxMessageLength = 1000;
const maxTitleLength = 40;

export const requestValidationSchema = (myRequest: MyRequest): Yup.ObjectSchema => {
    return Yup.object().shape({
        category: Yup.string().trim().required(myRequest.form.category.error.empty),
        title: Yup.string()
            .trim()
            .required(myRequest.form.title.error.empty)
            .max(maxTitleLength, myRequest.form.title.error.tooLong),
        message: Yup.string()
            .trim()
            .required(myRequest.form.message.error.empty)
            .max(maxMessageLength, myRequest.form.message.error.tooLong),
    });
};
