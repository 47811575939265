import { getTotalEarlySettlementContentEndpoint, TotalEarlySettlement } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<TotalEarlySettlement>({
    contentName: 'registrationEmail',
    contentEndpoint: getTotalEarlySettlementContentEndpoint,
});

export default reducer;
export const fetchTotalEarlySettlement = fetchContent;
