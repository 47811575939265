import { HeroImage, openInSameWindow, StaticPageLoadingPlaceholder } from '@cp-shared-7/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useCmsContent } from 'utils/useCmsContent';
import { ErrorPageCms } from 'common';

type ErrorType = 'not-found-error' | 'forbidden-error' | 'unauthorized-error';

export type ErrorPageProps = {
    type: ErrorType;
};

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const ErrorPage: React.FC<ErrorPageProps> = ({ type }) => {
    const [cmsContent, isCmsLoading, cmsError] = useCmsContent<ErrorPageCms>(type);

    return (
        <HeroImageWithHandlers
            isLoading={isCmsLoading}
            hasError={!!cmsError}
            pretitle={cmsContent?.pretitle}
            title={cmsContent?.title || ''}
            subTitle={<span dangerouslySetInnerHTML={{ __html: cmsContent?.subTitle || '' }} />}
            buttonText={cmsContent?.buttonText}
            clickHandler={openInSameWindow(cmsContent?.buttonUrl)}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
        />
    );
};
