import { RegistrationEmail } from 'common';
import { preventSubmit, useAuthentication, ValidatedInput } from '@cp-shared-7/frontend-ui';
import { Button, ContentSection, Fieldset, Form, Layout, Logo, PageWrap } from '@vwfs-bronson/bronson-react';
import { loginInProgressPagePath } from 'components/navigation/paths';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { currentBrand, idpHint } from '../../../../config';
import { RegistrationEmailFormFields } from './RegistrationEmailFormFields';
import { registrationEmailValidationSchema } from './registrationEmailValidationSchema';

export const RegistrationEmailUi: React.FC<{ registrationEmail?: RegistrationEmail }> = ({ registrationEmail }) => {
    const { i18n } = useTranslation();
    const { login } = useAuthentication();
    const language = i18n.languages[0];
    let logo;

    function redirectToIdentityKit(email: string): Promise<void> {
        return login({
            redirectUri: window.location.origin + loginInProgressPagePath(),
            locale: language,
            loginHint: email,
            idpHint,
            prompt: 'login',
        });
    }

    if (!registrationEmail) {
        return null;
    }

    if (currentBrand === 'df') {
        logo = (
            <Layout.Item className={'u-text-center'}>
                <img src={registrationEmail.logoUrl} className={'u-1/3 u-1/2@s u-1/1@xs'} alt="logo_DutchFinance" />
            </Layout.Item>
        );
    } else {
        logo = (
            <Layout.Item>
                <Logo className={'u-block-center'} />
            </Layout.Item>
        );
    }

    return (
        <ContentSection>
            <PageWrap size={'xsmall'}>
                <Layout>
                    {logo}
                    <Layout.Item className={'u-text-center'}>
                        <h2>{registrationEmail.headline}</h2>
                        <p>{registrationEmail.description.line1}</p>
                        <b>{registrationEmail.description.line2}</b>
                    </Layout.Item>
                    <Layout.Item>
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={registrationEmailValidationSchema(registrationEmail)}
                            onSubmit={(values: RegistrationEmailFormFields): void => {
                                redirectToIdentityKit(values.email);
                            }}
                        >
                            {(formik) => (
                                <Form onSubmit={preventSubmit()}>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ValidatedInput
                                                className="u-text-left "
                                                label={registrationEmail.email.label}
                                                name="email"
                                                inputMode={'email'}
                                                testId={'email'}
                                            />
                                        </Fieldset.Row>
                                        <Fieldset.Row className={'u-text-center'}>
                                            <Button onClick={formik.submitForm} testId={'continueButton'}>
                                                {registrationEmail.continue}
                                            </Button>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </Form>
                            )}
                        </Formik>
                    </Layout.Item>
                </Layout>
            </PageWrap>
        </ContentSection>
    );
};
