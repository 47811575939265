import React from 'react';
import { SmsVerificationError } from 'common';
import { Notification, NotificationStatus } from '@cp-shared-7/frontend-ui';

type AlertProps = {
    errorCode: string;
    errorMessages: SmsVerificationError;
};

export const SmsIdentificationAlert: React.FC<AlertProps> = ({ errorCode, errorMessages }) => {
    const getNotificationProps = (): { status: NotificationStatus; text: string } | undefined => {
        switch (errorCode) {
            case 'CUSTOMER_NOT_FOUND':
            case 'INCORRECT_VERIFICATION_CODE':
                return {
                    status: NotificationStatus.info,
                    text: errorMessages.invalidCodeOrUser,
                };
            case 'CUSTOMER_ALREADY_VERIFIED':
                return {
                    status: NotificationStatus.info,
                    text: errorMessages.userAlreadyRegistered,
                };
            case 'MAX_VERIFICATIONS_NR_REACHED':
                return {
                    status: NotificationStatus.warning,
                    text: errorMessages.maxVerificationNumberReached,
                };
        }
    };

    const notificationProps = getNotificationProps();
    if (!notificationProps) return null;

    return (
        <Notification status={notificationProps.status} testId="sms-identification-alert">
            {notificationProps.text}
        </Notification>
    );
};
