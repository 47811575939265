import { getMyProfileEndpoint, MyProfile } from 'common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-7/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<MyProfile, DefaultBusinessMarketApiErrorCode>({
    dataName: 'myProfile',
    fetchCallback() {
        return CpDataApi.get(getMyProfileEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchMyProfile = fetchData;
