import { getMyRequestEndpoint, MyRequest } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<MyRequest>({
    contentName: 'myRequest',
    contentEndpoint: getMyRequestEndpoint,
});

export default reducer;
export const fetchMyRequest = fetchContent;
