import { Contract } from 'common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';

import { FinancialDetailsUi } from './ui';
import { useFinancialDetails } from './useFinancialDetails';

const FinancialDetailsWithHandlers = withLoadingAndNoConnectionHandler(FinancialDetailsUi);

type FinancialDetailsProps = {
    contract: Contract;
};

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({ contract }) => {
    const { data: financialDetails, isLoading, loadingError } = useFinancialDetails(contract.contractNumber);

    return (
        <FinancialDetailsWithHandlers
            isLoading={isLoading}
            financialDetails={financialDetails}
            hasError={!!loadingError}
        />
    );
};
