import { IbanChangeResult } from '../bank-details-section/edit-view/validated-iban-input/types';
import {
    Address,
    ContactDetails,
    BankDetails,
    CompanyDetails,
    IbanChange,
    Identification,
    MyProfile,
    ContactDetailsChange,
    BankDetailsSectionContentCms,
    ErrorTypes,
} from 'common';

export const IdentificationSectionWithAllValues: Identification = {
    initials: 'I.V.V.',
    firstName: 'Inge',
    middleName: 'Van',
    surname: 'Vrugteveen',
};

export const IdentificationSectionOnlyWithMandatoryValues: Identification = {
    initials: 'V.',
    surname: 'Vrugteveen',
};

export const BankDetailsOnlyWithMandatoryValues: BankDetails = {
    iban: 'NL91 ABNA 0417 1643 00',
};

export const BankDetailsWithAllValues: BankDetails = {
    iban: 'NL91 ABNA 0417 1643 00',
    paymentMethod: 'Automatische incasso',
};

export const ContactDetailsWithAllValues: ContactDetails = {
    email: 'ivrugteveen@vwpfs.nl',
    mobilePhone: '+49 5730253178',
    mainPhone: '+49 7612345678',
};

export const NlContactDetailsWithAllValues: ContactDetails = {
    email: 'ivrugteveen@vwpfs.nl',
    mobilePhone: '+31 630253178',
    mainPhone: '+31 761234567',
};

export const ContactDetailsWithNoMainPhoneValue: ContactDetails = {
    email: 'ivrugteveen@vwpfs.nl',
    mobilePhone: '+49 5730253178',
};

export const ContactDetailsChangeWithAllValues: ContactDetailsChange = {
    emailAddress: 'ivrugteveen@vwpfs.nl',
    mobilePhoneCountryCode: '+49',
    mobilePhone: '5730253178',
    mainPhoneCountryCode: '+50',
    mainPhone: '12345678910',
    oldEmailAddress: 'test@test.com',
};

export const AddressWithAllFields: Address = {
    visitAddressCountry: 'Nederland',
    visitAddressLine1: 'Schout van Wijngaertlaan 11',
    visitAddressLine2: "5237 WB 's-Hertogenbosch",
    postalAddressCountry: 'Nederland',
    postalAddressLine1: 'Hemonylaan 25A',
    postalAddressLine2: '1074 BJ Amsterdam',
};

export const AddressPostalOnly: Address = {
    postalAddressCountry: 'Nederland',
    postalAddressLine2: '1074 BJ Amsterdam',
};

export const AddressVisitOnly: Address = {
    visitAddressCountry: 'Nederland',
    visitAddressLine1: 'Schout van Wijngaertlaan 11',
    visitAddressLine2: "5237 WB 's-Hertogenbosch",
};

export const AddressEmpty: Address = {
    postalAddressCountry: undefined,
    postalAddressLine1: '',
    postalAddressLine2: '',
    visitAddressCountry: undefined,
    visitAddressLine1: '',
    visitAddressLine2: '',
};

export const CompanyDetailsWithAllValues: CompanyDetails = {
    companyName: 'Ivrugteveen',
    kvkNumber: '111111111',
    businessEmail: 'ivrugteveen@vwpfs.nl',
    businessMobilePhone: '+49 5730253178',
    businessMainPhone: '+49 7612345678',
};

export const MyProfileWithAllSections: MyProfile = {
    identification: IdentificationSectionWithAllValues,
    bankDetails: BankDetailsWithAllValues,
    personalAddress: AddressWithAllFields,
    companyDetails: CompanyDetailsWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
};

export const MyProfileWithNoPersonInfSections: MyProfile = {
    bankDetails: BankDetailsWithAllValues,
    personalAddress: AddressWithAllFields,
    companyDetails: CompanyDetailsWithAllValues,
};

export const MyProfileWithNoContactDetailsSections: MyProfile = {
    identification: IdentificationSectionWithAllValues,
    bankDetails: BankDetailsWithAllValues,
    personalAddress: AddressWithAllFields,
    companyDetails: CompanyDetailsWithAllValues,
};

export const MyProfileWithNoCompanyDetailsSections: MyProfile = {
    identification: IdentificationSectionWithAllValues,
    bankDetails: BankDetailsWithAllValues,
    personalAddress: AddressWithAllFields,
    contactDetails: ContactDetailsWithAllValues,
};

export const MyProfileWithNoBankDetailsSections: MyProfile = {
    identification: IdentificationSectionWithAllValues,
    personalAddress: AddressWithAllFields,
    companyDetails: CompanyDetailsWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
};

export const MyProfileWithNoAddressSections: MyProfile = {
    identification: IdentificationSectionWithAllValues,
    bankDetails: BankDetailsWithAllValues,
    companyDetails: CompanyDetailsWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
};

export const bankDetailsSectionContentCms: BankDetailsSectionContentCms = {
    editButtonLabel: 'WIJZIG',
    headline: 'IBAN wijzigen',
    notification: {
        info: 'Deze wijziging werkt door op alle contracten. Neem contact op als dat niet gewenst is.',
        success: 'De IBAN-wijziging is succesvol.',
        manualChange: 'Het wijzigingsverzoek is succesvol ontvangen en wordt zo spoedig mogelijk verwerkt.',
        required2Factor: {
            headline: 'Bevestiging nodig',
            text: 'U ontvangt een mail waarmee u deze wijziging goedkeurt. Daarna wordt de wijziging verwerkt.',
        },
        error: {
            headline: 'Wij kunnen uw gegevens niet tonen',
            text: 'Sorry, er is een technische fout opgetreden. Probeer het later nog eens.',
        },
    },
    form: {
        newIBANInputLabel: 'IBAN*',
        newIBANInputTooltip: 'Voer hier uw IBAN nummer in',
        notSameName: {
            label: 'Is de IBAN-rekening tenaamgesteld op uw naam?*',
            tooltip: 'Geef een toelichting op de wijziging als de IBAN niet op uw naam staat.',
            yes: 'Ja',
            no: 'Nee',
        },
        requestText: {
            label: 'Toelichting*',
            tooltip: 'Bij afwijkende IBAN-tenaamstelling',
        },
        IBANName: {
            label: 'Tenaamstelling IBAN*',
            tooltip: 'Geef de tenaamstelling van de nieuwe IBAN',
        },
        backButton: 'Terug',
        submitButton: 'Bevestigen',
        validationErrors: {
            iban: {
                required: 'Verplicht veld',
                invalid: 'De ingevoerde IBAN heeft niet het juiste formaat',
                same: 'Het ingevoerde IBAN is identiek aan het huidige IBAN.',
                unavailableApi: 'Sorry, er is een technische fout opgetreden. Probeer het later nog eens.',
            },
            ibanNameRequired: 'Verplicht veld',
            requestTextRequired: 'Verplicht veld',
            requestTextMax: 'Mag maximaal 200 letters bevatten',
        },
    },
};

export const ibanChangeSuccessResponse: IbanChangeResult = {
    elementName: 'ibanChangeSuccesful',
    type: 'Succes',
    errorMessage: 'De IBAN-wijziging is succesvol',
};
export const changeIbanRequestBody: IbanChange = {
    iban: 'NL91ABNA0417164300',
    isForeignIBAN: false,
    notSameName: false,
    ibanName: '',
    requestText: '',
};

export const errors: ErrorTypes = {
    internalServerError: {
        header: 'Wij kunnen uw gegevens niet tonen',
        content: 'Onze excuses. Er is een technische fout opgetreden. Probeer het later nog eens.',
    },
    serviceUnavailable: {
        header: 'Wij kunnen uw gegevens niet tonen',
        content: 'Onze excuses. Er wordt op dit moment onderhoud gepleegd aan het systeem. Probeer het later nog eens.',
    },
    default: {
        header: 'Wij kunnen uw gegevens niet tonen',
        content:
            "Sorry, er is een technische fout opgetreden. Probeer het later nog eens of neem contact op met <a href='mailto:customerservice@audifinancialservices.nl'>customer service</a>.",
    },
};

export const apiNotReachableError = {
    status: 502,
    code: 'MARKET_API_DEFAULT_BUSINESS_ERROR',
    message: 'The market API responded with an unexpected/ignored error code',
};

export const internalServerError = {
    status: 502,
    code: 'MARKET_API_INTERNAL_SERVER_ERROR',
    message: 'The market API responded with an error indicating a technical problem',
};

export const serviceUnavailableError = {
    status: 502,
    code: 'MARKET_API_SERVICE_UNAVAILABLE',
    message: 'The market API is currently unavailable',
};
