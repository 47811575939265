import { getSimpleDataFetchSlice } from '@cp-shared-7/frontend-integration';
import { ContractCount, getContractsCountEndpoint } from 'common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<ContractCount[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'contractsCount',
    fetchCallback: () => CpDataApi.get(getContractsCountEndpoint()).then((res) => res.data),
});

export default reducer;
export const fetchContractsCount = fetchData;
