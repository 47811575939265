import React from 'react';
import { CpDataApi } from 'cp-xhr';
import { getMyProfileEndpoint } from 'common';
import { MyProfileWithAllSections } from './ExampleData';
import { DefaultBusinessMarketApiErrorCode, DefaultBusinessMarketApiError } from '@cp-shared-7/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-7/frontend-storybook-extensions';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfileWithAllSections,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Profile data',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getMyProfileEndpoint(),
};

export const MyProfileResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyProfileMock]);

    return <>{storyFn()}</>;
};
