import { Brand, FaqDto } from 'common';
import { Faq as FaqShared, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-7/frontend-ui';
import { ContentSection, HeroTeaser } from '@vwfs-bronson/bronson-react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { currentBrand } from 'config';

/**
 * Shared view for Public and Private Faq components
 * @param faq
 */
const FaqView: React.FC<{ faq?: FaqDto }> = ({ faq }) => {
    const { onAction } = useAnalyticsActionTracker('onFaqQuestion');
    useAnalyticsPageViewTracker('faq', !!faq);

    const teaser = faq?.teaser;
    const { header, descriptionText, items } = faq?.content || { header: '', items: [] };

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        onAction(refs[refs.length - 1]);
    };

    const contentComponent = (
        <FaqShared header={header} descriptionText={descriptionText} items={items} onChange={handleOnChange} />
    );

    const otherProps =
        currentBrand === Brand.VWCV || currentBrand === Brand.VW ? { style: { maxHeight: '440px' } } : {};

    return (
        <>
            {teaser && (
                <HeroTeaser
                    title={teaser.title}
                    imgProps={{ src: teaser.imageUrl }}
                    inverted={!!teaser.isInverted}
                    shallow
                    {...otherProps}
                />
            )}
            <ContentSection pageWrapSize="medium">{contentComponent}</ContentSection>
        </>
    );
};

export const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqView);
