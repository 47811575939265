import { ContractHandoverCms } from 'common';
import { Modal } from '@vwfs-bronson/bronson-react';
import React from 'react';

type RequestModelProps = {
    showModal: boolean;
    status: string;
    closeDialog: () => void;
    cmsContent?: ContractHandoverCms;
};

export const ContractHandoverModal: React.FC<RequestModelProps> = ({ cmsContent, showModal, status, closeDialog }) => {
    return (
        <Modal
            shown={showModal}
            status={status}
            buttonConfirmText={
                status === 'success'
                    ? cmsContent?.submitResponse.success.button
                    : cmsContent?.submitResponse.error.button
            }
            title={
                status === 'success' ? cmsContent?.submitResponse.success.title : cmsContent?.submitResponse.error.title
            }
            onConfirm={closeDialog}
            onClickOutside={closeDialog}
            onClose={closeDialog}
            testId={`confirmModal-${status}`}
        >
            {status === 'success' ? cmsContent?.submitResponse.success.text : cmsContent?.submitResponse.error.text}
        </Modal>
    );
};
