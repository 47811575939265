import React, { useEffect, useState } from 'react';
import { CustomerVerificationError, SmsCodeContent, SmsSendingError, SmsSendingInfo } from 'common';
import { Error, WithDefaultCpIntegrationErrors } from '@cp-shared-7/common-utilities';
import { handleSendSms, handleSubmitForm } from './Utils';
import { SmsIdentificationUi } from './ui';
import {
    RegistrationLoadingPlaceholder,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-7/frontend-ui';
import { useCmsContent } from '../../../utils/useCmsContent';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { dashboardPagePath } from '../../navigation/paths';
import { useHistory } from 'react-router';

const SmsIdentificationWithHandlers = withLoadingAndNoConnectionHandler(SmsIdentificationUi);
const MAX_SMS_LIMIT = 3;
type RequestProps = {
    isLoading: boolean;
    error?: Error<WithDefaultCpIntegrationErrors<SmsSendingError | CustomerVerificationError>>;
};

export type SmsIdentificationProps = {
    customerNotFoundCallback: () => void;
    goBack: () => void;
};

export const SmsIdentification: React.FC<SmsIdentificationProps> = ({ customerNotFoundCallback, goBack }) => {
    const [smsSendingRequest, setSmsSendingRequest] = useState<RequestProps>({ isLoading: true });
    const [formSubmissionRequest, setFormSubmissionRequest] = useState<RequestProps>({ isLoading: false });
    const [isMaxSmsSend, setIsMaxSmsSend] = useState(false);
    const [isResendLinkHidden, setIsResendLinkHidden] = useState(false);
    const [maskedPhoneNumber, setMaskedPhoneNumber] = useState('');
    const [smsPageContent, isConetntLoading, contentLoadingError] = useCmsContent<SmsCodeContent>('sms-code-page');

    useAnalyticsPageViewTracker('enterSmsCode');
    const { onError: trackOnError } = useAnalyticsFormTracker({
        confirmError: 'onConfirmIdentiyAuthFailed',
    });
    const { onAction: trackOnSuccess } = useAnalyticsActionTracker('onEnterSmsCodeSuccess');

    const history = useHistory();

    const sendSmsCode = (): void => {
        const onSucess = (data: SmsSendingInfo): void => {
            const { mobileNumber, numberOfSmsSent } = data;
            setMaskedPhoneNumber(`${mobileNumber.slice(0, 2)}*****${mobileNumber.slice(7)}`);
            if (numberOfSmsSent > MAX_SMS_LIMIT) setIsMaxSmsSend(true);
            if (numberOfSmsSent >= MAX_SMS_LIMIT) setIsResendLinkHidden(true);
            setSmsSendingRequest({ isLoading: false });
        };

        const onError = (error: Error<WithDefaultCpIntegrationErrors<SmsSendingError>>): void => {
            if (error?.code === 'CUSTOMER_NOT_FOUND') return customerNotFoundCallback();
            setSmsSendingRequest({ isLoading: false, error });
        };

        handleSendSms(onSucess, onError);
    };

    const handleSubmit = ({ smsCode }: { smsCode: string }): void => {
        setFormSubmissionRequest({ isLoading: true });
        const onSuccess = (): void => {
            trackOnSuccess();
            history.push(dashboardPagePath());
        };
        const onError = (error: Error<WithDefaultCpIntegrationErrors<CustomerVerificationError>>): void => {
            trackOnError();
            setFormSubmissionRequest({ isLoading: false, error });
        };

        handleSubmitForm(smsCode, onSuccess, onError);
    };

    useEffect(() => {
        sendSmsCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {formSubmissionRequest.isLoading && <Spinner fullPage={true} />}
            <SmsIdentificationWithHandlers
                isLoading={isConetntLoading || smsSendingRequest.isLoading}
                hasError={!!contentLoadingError}
                smsPageContent={smsPageContent}
                maskedPhoneNumber={maskedPhoneNumber}
                isMaxSmsSend={isMaxSmsSend}
                isResendLinkHidden={isResendLinkHidden}
                sendSmsCode={sendSmsCode}
                handleSubmit={handleSubmit}
                goBack={goBack}
                sendSmsErrorCode={smsSendingRequest?.error?.code}
                verificationErrorCode={formSubmissionRequest?.error?.code}
                loadingPlaceholder={<RegistrationLoadingPlaceholder />}
            />
        </>
    );
};
