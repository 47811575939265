import {
    getOpenVerificationEndpoint,
    OpenVerificationError,
    OpenVerificationFormData,
    OpenVerificationInfo,
    OpenVerificationContent,
} from 'common';
import { parseErrorResponse } from '@cp-shared-7/frontend-integration';
import { RegistrationLoadingPlaceholder, ScrollToTopOnNavigation, Spinner } from '@cp-shared-7/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Logo } from 'components/common/Logo';
import { CpDataApi } from 'cp-xhr';
import React, { useState } from 'react';
import { OpenVerificationAlert } from './ui';
import { OpenVerificationForm } from './ui/open-verification-form';
import { useCmsContent } from '../../../utils/useCmsContent';
import { withLoadingHandler } from '../../integration-wrapper';

type RequestProps = {
    isLoading: boolean;
    errorCode?: string;
};

export type OpenVerificationProps = {
    handleOpenVerificationConfirm: (data: OpenVerificationInfo) => void;
};

const OpenVerificationFormWithHandlers = withLoadingHandler(OpenVerificationForm);

export const OpenVerification: React.FC<OpenVerificationProps> = ({ handleOpenVerificationConfirm }) => {
    const [openVerificationRequest, setOpenVerificationRequest] = useState<RequestProps>({ isLoading: false });
    const [openVerificationContent, isContentLoading] = useCmsContent<OpenVerificationContent>(
        'open-verification-content',
    );

    const handleSubmit = (data: OpenVerificationFormData): void => {
        setOpenVerificationRequest({ isLoading: true });
        CpDataApi.post(getOpenVerificationEndpoint(), data)
            .then((response) => {
                setOpenVerificationRequest({ isLoading: false });
                handleOpenVerificationConfirm(response.data as OpenVerificationInfo);
            })
            .catch((error) => {
                setOpenVerificationRequest({
                    isLoading: false,
                    errorCode: parseErrorResponse<OpenVerificationError>(error)?.code,
                });
            });
    };

    return (
        <>
            {openVerificationRequest.isLoading && <Spinner fullPage={true} />}
            <Layout>
                <Layout.Item className={'u-text-center'}>
                    <Logo />
                </Layout.Item>
                {openVerificationRequest.errorCode && (
                    <Layout.Item className={'u-text-center'}>
                        <ScrollToTopOnNavigation>
                            <OpenVerificationAlert errorCode={openVerificationRequest.errorCode} />
                        </ScrollToTopOnNavigation>
                    </Layout.Item>
                )}
                <Layout.Item className={'u-text-center'}>
                    {openVerificationRequest.errorCode !== 'MAX_ATTEMPTS_REACHED' && (
                        <OpenVerificationFormWithHandlers
                            handleSubmit={handleSubmit}
                            isLoading={isContentLoading}
                            openVerificationContent={openVerificationContent}
                            loadingPlaceholder={<RegistrationLoadingPlaceholder />}
                        />
                    )}
                </Layout.Item>
            </Layout>
        </>
    );
};
