import React from 'react';
import { useCmsContent } from 'utils/useCmsContent';
import { ContractsCms } from 'common';
import { ContractHandoverForm } from './ui/ContractHandoverForm';
import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { dashboardPagePath } from 'components/navigation/paths';
import { useHistory } from 'react-router-dom';
import { RequestLoadingPlaceholder } from '@cp-shared-7/frontend-ui';

const ContractHandoverWithHandlers = withLoadingAndNoConnectionHandler(ContractHandoverForm);

type ContractHandoverProps = {
    contractNumber: string;
};
export const ContractHandover: React.FC<ContractHandoverProps> = ({ contractNumber }) => {
    const [cmsContent, isCmsLoading, cmsError] = useCmsContent<ContractsCms>('contracts');

    const { data, isLoading, loadingError } = useContract(contractNumber || '');
    const history = useHistory();

    const onCancel = (): void => {
        history.push(dashboardPagePath());
    };

    return (
        <ContractHandoverWithHandlers
            isLoading={isCmsLoading || isLoading}
            contract={data}
            hasError={!!cmsError || !!loadingError}
            cmsContent={cmsContent?.contractHandover}
            loadingPlaceholder={<RequestLoadingPlaceholder withTextArea numberOfButtons={2} />}
            onCancel={onCancel}
        />
    );
};
