import { Address, ChangeAddress } from 'common';
import { useAnalyticsPageViewTracker } from '@cp-shared-7/frontend-ui';
import { Select, DataOverview, Fieldset, Layout, Notification, FormField } from '@vwfs-bronson/bronson-react';
import { countryCodeMapping } from 'components/my-profile/contact-section/edit-view/country-code';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressView } from '../address-view/AddressView';
import { EditStatus } from '../address-view/NotificationForEditStatus';
import { AddressFormNL } from './forms/address-form-nl/AddressFormNL';
import { AddressFormNotNL } from './forms/address-form-not-nl/AddressFormNotNL';

type ChangeAddressProps = {
    formValues: ChangeAddress;
    onCancel: (values?: ChangeAddress) => void;
    finishEditing: (newEditStatus: EditStatus, updatedAddress?: Address) => void;
    onEdit: (addressType: string) => void;
    addressType: string;
    editStatus: EditStatus;
    address?: Address;
};

export const AddressChange: React.FC<ChangeAddressProps> = ({
    formValues,
    onCancel,
    finishEditing,
    onEdit,
    addressType,
    editStatus,
    address,
}) => {
    const { t } = useTranslation('change-address');

    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, 'Address');

    const [formValuesState, setFormValuesState] = useState<ChangeAddress>({
        country: formValues?.country || '',
        postCode: formValues?.postCode || '',
        houseNumber: formValues?.houseNumber || '',
        door: formValues?.door || '',
        street: formValues?.street || '',
        locality: formValues?.locality || '',
        extraInfo: formValues?.extraInfo || '',
    });

    const handleCountrySelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFormValuesState({
            ...formValuesState,
            country: event.target.value,
        });
    };
    const getCountryOptions = (): JSX.Element[] => {
        return Object.entries(countryCodeMapping).map((value: [string, string[]], index: number) => (
            <Select.Item key={index} option={value[1][1]} value={value[1][1]}>
                {value[0]}
            </Select.Item>
        ));
    };
    const getFormProps = () => {
        return {
            formValues: formValuesState,
            onCancel: onCancel,
            finishEditing: finishEditing,
            onEdit: onEdit,
            addressType: addressType,
            editStatus: editStatus,
            address: address,
        };
    };

    return (
        <>
            <h3 className={'u-mt'}>{t('main-headline')}</h3>
            {addressType === 'Postal' && address?.postalAddressCountry && (
                <AddressView address={address} onEdit={onEdit} addressType={'Postal'} editStatus={editStatus} />
            )}
            {addressType === 'Visit' && address?.visitAddressCountry && (
                <AddressView address={address} onEdit={onEdit} addressType={'Visit'} editStatus={editStatus} />
            )}
            <DataOverview title={t('headline')}>
                <Notification testId="notification" status="info" showCloseButton={false} visible className="u-mb">
                    {t('address-change-info')}
                </Notification>
                <div>
                    <Fieldset>
                        <Fieldset.Row>
                            <Layout>
                                <Layout.Item default="1/2">
                                    <FormField type="select" labelText={t('form.country')}>
                                        <Select
                                            icon={'semantic-expand'}
                                            label={t('form.country')}
                                            onChange={handleCountrySelection}
                                            defaultValue={'NL'}
                                        >
                                            {getCountryOptions()}
                                        </Select>
                                    </FormField>
                                </Layout.Item>
                            </Layout>
                        </Fieldset.Row>
                    </Fieldset>
                    {formValuesState.country === 'NL' ? (
                        <AddressFormNL {...getFormProps()} />
                    ) : (
                        <AddressFormNotNL {...getFormProps()} />
                    )}
                </div>
            </DataOverview>
        </>
    );
};
