import { SmsCodeContent } from 'common';
import { Notification, NotificationStatus } from '@cp-shared-7/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Logo } from 'components/common/Logo';
import React from 'react';
import { SmsIdentificationForm } from './sms-identification-form';
import { SmsIdentificationAlert } from './SmsIdentificationAlert';

export type SmsIdentificationUiProps = {
    smsPageContent?: SmsCodeContent;
    maskedPhoneNumber: string;
    isMaxSmsSend: boolean;
    isResendLinkHidden: boolean;
    sendSmsCode: () => void;
    handleSubmit: ({ smsCode }: { smsCode: string }) => void;
    goBack: () => void;
    sendSmsErrorCode?: string;
    verificationErrorCode?: string;
};

const createErrorMessageForSmsSendingError = (text: string, resendLinkText: string, resendLinkHandler: Function) => {
    const splitedMessage = text.split('#RESEND_LINK#');
    return (
        <>
            {splitedMessage[0]}
            <label onClick={(): void => resendLinkHandler()} className="u-base-link">
                {resendLinkText}
            </label>
            {splitedMessage[1]}
        </>
    );
};

export const SmsIdentificationUi: React.FC<SmsIdentificationUiProps> = ({
    smsPageContent,
    maskedPhoneNumber,
    isMaxSmsSend,
    isResendLinkHidden,
    sendSmsCode,
    handleSubmit,
    goBack,
    sendSmsErrorCode,
    verificationErrorCode,
}) => {
    if (!smsPageContent) return null;
    if (
        verificationErrorCode === 'MARKET_API_DEFAULT_BUSINESS_ERROR' ||
        sendSmsErrorCode === 'MARKET_API_DEFAULT_BUSINESS_ERROR' ||
        sendSmsErrorCode === 'CP_API_NOT_REACHABLE' ||
        sendSmsErrorCode === 'CP_API_NOT_REACHABLE'
    )
        return (
            <Notification status={NotificationStatus.error} testId="no-connection-error">
                {smsPageContent.noConnectionError}
            </Notification>
        );

    if (sendSmsErrorCode === 'SMS_SENDING_ERROR')
        return (
            <Notification status={NotificationStatus.error} testId="sms-sending-error">
                {createErrorMessageForSmsSendingError(
                    smsPageContent.smsSendingError.sendingProblem,
                    smsPageContent.smsSendingError.resendLinkLabel,
                    sendSmsCode,
                )}
            </Notification>
        );

    const infoBoxContent = (
        <>
            {smsPageContent.infoBox.beforeLink.replace('#CUSTOMER_NUMBER#', maskedPhoneNumber)}
            <button className="u-base-link" onClick={() => goBack()}>
                {smsPageContent.infoBox.linkContantWithWrongNumber}
            </button>
            {smsPageContent.infoBox.afterLink}
        </>
    );

    return (
        <>
            <Layout>
                <Layout.Item className={'u-text-center'}>
                    <Logo />
                </Layout.Item>
                <Layout.Item className={'u-text-center'}>
                    <h1>{smsPageContent.headline}</h1>
                </Layout.Item>
                {(isMaxSmsSend || !!verificationErrorCode) && (
                    <Layout.Item className={'u-mb u-mt-none'}>
                        {isMaxSmsSend && (
                            <Notification status={NotificationStatus.warning} testId="sms-sending-warning">
                                {smsPageContent.smsSendingError.maxAmountOfSms}
                            </Notification>
                        )}
                        {!!verificationErrorCode && (
                            <SmsIdentificationAlert
                                errorCode={verificationErrorCode}
                                errorMessages={smsPageContent.smsVerificationError}
                            />
                        )}
                    </Layout.Item>
                )}
                <Layout.Item className={'u-m-xxsmall'}>
                    <span>{infoBoxContent}</span>
                    &nbsp;
                    {!isResendLinkHidden && (
                        <label className="u-base-link" onClick={(): void => sendSmsCode()}>
                            {smsPageContent.resendLinkLabel}
                        </label>
                    )}
                </Layout.Item>
                {!isMaxSmsSend && verificationErrorCode !== 'MAX_VERIFICATIONS_NR_REACHED' && (
                    <SmsIdentificationForm
                        content={smsPageContent.form}
                        onSubmit={handleSubmit}
                        data-testid="identification-form"
                    />
                )}
            </Layout>
        </>
    );
};
