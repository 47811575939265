import { ContactSectionContent, getContactSectionContentEndpoint } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ContactSectionContent>({
    contentName: 'contactSection',
    contentEndpoint: getContactSectionContentEndpoint,
});

export default reducer;
export const fetchContactSection = fetchContent;
