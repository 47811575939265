import { ContactDetails } from 'common';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';
import { EditStatus } from '../edit-view/EditView';
import { ContactList } from './ContactList';
import { NotificationForEditStatus } from './NotificationForEditStatus';

export type NonEditViewProps = {
    contactDetails?: ContactDetails;
    startEditing: () => void;
    editStatus: EditStatus;
};

export const NonEditView: React.FC<NonEditViewProps> = ({ contactDetails, startEditing, editStatus }) => {
    const { t } = useTranslation('my-profile');

    return (
        <DataOverview
            title={t('contact-details.sub-headline')}
            buttonLabel={t('contact-details.edit-button')}
            buttonProps={{ onClick: startEditing, testId: 'editButton' }}
        >
            {!contactDetails ? (
                <NoConnectionNotification testId="identification-no-connection-error" />
            ) : (
                <>
                    <ContactList contactDetails={contactDetails} />
                    <NotificationForEditStatus editStatus={editStatus} />
                </>
            )}
        </DataOverview>
    );
};
