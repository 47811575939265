import { OpenVerificationInfo } from 'common';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import React, { useEffect, useState } from 'react';
import { ContactDetailsCheck } from './contact-details-check/ContactDetailsCheck';
import { OpenVerification } from './open-verification';
import { SmsIdentification } from './sms-identification';

type ViewType = 'OPEN_VERIFICATION' | 'CONTACT_DETAILS_CHECK' | 'SMS_IDENTIFICATION';

export const Identification: React.FC = () => {
    const [viewType, setViewType] = useState<ViewType>('SMS_IDENTIFICATION');
    const [openVerificationInfo, setOpenVerificationInfo] = useState<OpenVerificationInfo>();

    useEffect(() => {
        if (viewType === 'CONTACT_DETAILS_CHECK' && !openVerificationInfo) {
            setViewType('OPEN_VERIFICATION');
        }
    }, [viewType]);

    return (
        <ContentSection>
            <PageWrap size={'xsmall'}>
                {viewType === 'OPEN_VERIFICATION' && (
                    <OpenVerification
                        handleOpenVerificationConfirm={(data) => {
                            setOpenVerificationInfo(data);
                            setViewType('CONTACT_DETAILS_CHECK');
                        }}
                    />
                )}
                {viewType === 'CONTACT_DETAILS_CHECK' && openVerificationInfo && (
                    <div>
                        <ContactDetailsCheck
                            openVerificationInfo={openVerificationInfo}
                            handleSmsRedirect={() => setViewType('SMS_IDENTIFICATION')}
                        />
                    </div>
                )}
                {viewType === 'SMS_IDENTIFICATION' && (
                    <SmsIdentification
                        customerNotFoundCallback={() => setViewType('OPEN_VERIFICATION')}
                        goBack={() => setViewType('CONTACT_DETAILS_CHECK')}
                    />
                )}
            </PageWrap>
        </ContentSection>
    );
};
