import { Contract } from 'common';
import React from 'react';

type ContractSelectItemProps = {
    contract: Contract;
};

export const ContractSelectItem: React.FC<ContractSelectItemProps> = ({ contract }) => (
    <div className="u-block">
        <strong>{contract.contractNumber}</strong>
        <br />
        <strong>{contract.productType}</strong>
        <br />
        {`${contract.carBrand || ''} ${contract.carModel || ''}${
            contract.licensePlate ? `, ${contract.licensePlate}` : ''
        }`}
    </div>
);
