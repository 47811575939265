import React from 'react';
import { MyProfileUi } from './ui';
import { useMyProfile } from './useMyProfile';
import { MyProfileLoadingPlaceholder, NotificationStatus, useAnalyticsPageViewTracker } from '@cp-shared-7/frontend-ui';
import { withLoadingAndCustomErrorNotificationHandler } from '../../integration-wrapper/withLoadingAndCustomErrorNotificationHandler';
import { ErrorOf } from '../../integration-wrapper/withCustomErrorNotification';
import { useCmsContent } from '../../../utils';
import { ErrorTypes } from 'common';

const MyProfileWithHandlers = withLoadingAndCustomErrorNotificationHandler<ErrorOf<typeof useMyProfile>>()(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { data: myProfile, isLoading, loadingError } = useMyProfile();
    const [errorTypes, areErrorTypesLoading] = useCmsContent<ErrorTypes>('errors');

    useAnalyticsPageViewTracker('profile', !!myProfile);

    return (
        <MyProfileWithHandlers
            myProfile={myProfile}
            isLoading={isLoading || areErrorTypesLoading}
            loadingPlaceholder={<MyProfileLoadingPlaceholder />}
            error={loadingError}
            errorMap={{
                MARKET_API_INTERNAL_SERVER_ERROR: {
                    status: NotificationStatus.error,
                    title: errorTypes?.internalServerError.header,
                    text: errorTypes?.internalServerError.content,
                },
                MARKET_API_SERVICE_UNAVAILABLE: {
                    status: NotificationStatus.error,
                    title: errorTypes?.serviceUnavailable.header,
                    text: errorTypes?.serviceUnavailable.content,
                },
                default: {
                    status: NotificationStatus.error,
                    title: errorTypes?.default.header,
                    text: errorTypes?.default.content,
                },
            }}
        />
    );
};
