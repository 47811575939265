import { getLegalFooterEndpoint, LegalFooter } from 'common';
import { createCmsContentSlice } from '../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LegalFooter>({
    contentName: 'legalFooter',
    contentEndpoint: getLegalFooterEndpoint,
});

export default reducer;
export const fetchLegalFooter = fetchContent;
