import React from 'react';
import { isEmpty } from 'lodash';
import { MyProfile } from 'common';
import { useTranslation } from 'react-i18next';
import { Notification } from '@vwfs-bronson/bronson-react';
import { NotificationStatus } from '@cp-shared-7/frontend-ui';
import { IdentificationSection } from 'components/my-profile/identification-section';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { BankDetailsSection } from 'components/my-profile/bank-details-section';
import { AddressSection } from 'components/my-profile/address-section/AddressSection';
import { CompanyDetailsSection } from 'components/my-profile/company-details-section/CompanyDetailsSection';
import { ContactSection } from 'components/my-profile/contact-section/ContactSection';

export const MyProfileUi: React.FC<{ myProfile?: MyProfile }> = ({ myProfile }) => {
    const { t } = useTranslation('my-profile');

    if (!myProfile || isEmpty(myProfile)) {
        return (
            <Notification
                visible
                showCloseButton={false}
                status={NotificationStatus.info}
                title={t('errors.noProfile.header')}
            >
                {t('errors.noProfile.content')}
            </Notification>
        );
    }

    const PersonInformationSection =
        !myProfile.identification && !myProfile.contactDetails ? (
            <NoConnectionNotification />
        ) : (
            <>
                <IdentificationSection identification={myProfile.identification} />
                <ContactSection contactDetails={myProfile.contactDetails} />
            </>
        );

    return (
        <>
            <h3 className={'u-mt'}>{t('section-headline-person-information')}</h3>
            {PersonInformationSection}
            <CompanyDetailsSection companyDetails={myProfile.companyDetails} />
            <AddressSection address={myProfile?.personalAddress} />
            <h3 className={'u-mt'}>{t('section-headline-payment-information')}</h3>
            <BankDetailsSection bankDetails={myProfile.bankDetails} />
        </>
    );
};
