import { getBrokerDetailsEndpoint } from 'common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-7/frontend-storybook-extensions';
import { brokerDetailswithAllValues } from 'components/dashboard-marketing-card/ExampleData';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { ContractsMock } from '../contracts/contractsMock';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 201,
        responseBody: brokerDetailswithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const BrokerDetailsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Broker Details Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getBrokerDetailsEndpoint('300047'),
};

export const BrokerDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, BrokerDetailsMock]);

    return <div>{storyFn()}</div>;
};
