import {
    Contract,
    ContractsCms,
    ContractsData,
    ContractStatus,
    ErrorTypes,
    getFinancialDetailsEndpoint,
    getInterveningPartiesEndpoint,
} from 'common';
import { formatCpDate } from '@cp-shared-7/common-utilities';

export const contractWithAllFields: Contract = {
    encryptedContractVersionId: 'NQYth-FzyJEsY_9rH3-iLg',
    contractStatus: ContractStatus.ACTIVE,
    contractNumber: '123456',
    carBrand: 'VW',
    carModel: 'Polo',
    licensePlate: '124-12',
    vinCode: 'WAUZZZ4G1FN076523',
    encryptedVinCode: 'ENCRYPTED_WAUZZZ4G1FN076523',
    productType: 'DutchFin Financial Lease',
    contractEndDate: formatCpDate('3000-12-20').toCpDate(),
    nextDueDate: formatCpDate('2020-12-20').toCpDate(),
    nextDueAmount: 250,
    outstandingBalance: 125.5,
    brokerBusinessPartnerId: '300047',
    _links: {
        financialDetails: getFinancialDetailsEndpoint('NQYth-FzyJEsY_9rH3-iLg', '123456'),
        interveningParties: getInterveningPartiesEndpoint('123456'),
    },
    unpaidBalance: {
        unpaidPresent: true,
        pendingInstallments: 5,
        nominalAmount: 1000.42,
        arrearsAmount: 1584.12,
        interestOnArrears: 35,
        totalUnpaidAmount: 14214.1,
        nextDueDate: formatCpDate('2020-12-20').toCpDate(),
        paymentDetailsEntity: '20641',
        paymentDetailsReference: '10854447',
    },
};

export const expiredContractWithAllFields: Contract = {
    encryptedContractVersionId: 'NQYth-FzyJEsY_9rH3-iLg',
    contractStatus: ContractStatus.EXPIRED,
    contractNumber: '56789',
    carBrand: 'VW',
    carModel: 'Polo',
    licensePlate: '124-12',
    vinCode: 'WAUZZZ4G1FN076523',
    encryptedVinCode: 'ENCRYPTED_WAUZZZ4G1FN076523',
    productType: 'Prive Plan',
    contractEndDate: formatCpDate('3000-12-20').toCpDate(),
    nextDueDate: formatCpDate('2020-12-20').toCpDate(),
    nextDueAmount: 250,
    outstandingBalance: 125.5,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('NQYth-FzyJEsY_9rH3-iLg', '12345'),
    },
};

export const contractNoOutstandingBalanceResponse: Contract = {
    encryptedContractVersionId: 'NQYth-FzyJEsY_9rH3-iLg',
    contractStatus: ContractStatus.ACTIVE,
    contractNumber: '123456',
    carBrand: 'VW',
    carModel: 'Polo',
    licensePlate: '124-12',
    vinCode: 'WAUZZZ4G1FN076523',
    encryptedVinCode: 'ENCRYPTED_WAUZZZ4G1FN076523',
    productType: 'DutchFin Financial Lease',
    contractEndDate: formatCpDate('3000-12-20').toCpDate(),
    nextDueDate: formatCpDate('2020-12-20').toCpDate(),
    nextDueAmount: 250,
    outstandingBalance: -1,
    brokerBusinessPartnerId: '300047',
    _links: {
        financialDetails: getFinancialDetailsEndpoint('NQYth-FzyJEsY_9rH3-iLg', '123456'),
        interveningParties: getInterveningPartiesEndpoint('123456'),
    },
};

export const contractWithMandatoryFields: Contract = {
    encryptedContractVersionId: 'NQYth-FzyJEsY_9rH3-iLg',
    carBrand: 'VW',
    contractNumber: '10111213',
    contractStatus: ContractStatus.ACTIVE,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('NQYth-FzyJEsY_9rH3-iLg', '12345'),
    },
};

export const validContracts: Contract[] = [
    contractWithAllFields,
    contractWithMandatoryFields,
    expiredContractWithAllFields,
];

export const contractDataWithValidContracts: ContractsData = {
    contracts: validContracts,
    isContractWithoutMandatoryFieldPresent: false,
};

export const noContractsForBrandError = {
    status: 502,
    code: 'NO_CONTRACT_FOUND_FOR_GIVEN_BRAND',
    message: 'No contracts found for given brand',
};

export const contractsCms: ContractsCms = {
    errors: {
        noContracts: {
            header: 'Wij kunnen de gegevens niet tonen',
            content: 'Er zijn geen contracten beschikbaar onder dit label.',
        },
    },
    contractHandover: {
        label: 'Contract overdragen',
        tooltip:
            'U kunt uw huidige contract met huidige condities overdragen aan een andere natuurlijke persoon of organisatie, mits de andere partij door ons wordt geaccepteerd.',
        headline: 'Contractovername',
        description: 'Een contractovername is van toepassing bij:',
        subheadline: 'Gegevens overnemende partij',
        companyName: 'Handelsnaam*',
        companyID: 'KVK-nummer*',
        initials: 'Voorletters',
        firstName: 'Voornaam',
        middleName: 'Tussenvoegsel',
        lastName: 'Achternaam',
        email: 'Emailadres',
        mobileNumberCountryCode: 'Landcode',
        mobileNumber: 'Mobiele nummer',
        backButton: 'Terug',
        submitButton: 'Verstuur',
        gender: 'Aanhef*',
        male: 'Dhr.',
        female: 'Mw.',
        consentFlag: 'Ik ga akkoord met de overname (onder voorbehoud van acceptatie van de overnemende partij).',
        submitResponse: {
            success: {
                title: 'Bedankt voor uw verzoek',
                text: 'De overnemende partij is succesvol uitgenodigd.',
                button: 'Overzicht',
            },
            error: {
                title: 'Uw verzoek is niet verzonden',
                text:
                    'Door een technische storing kunnen wij het verzoek niet in behandeling nemen. Probeer het later nog eens.',
                button: 'Terug',
            },
        },
    },
    unpaidBalance: {
        headline: 'OPENSTAANDE TERMIJN',
        text:
            'We hebben gezien dat er nog een termijn openstaat. Graag zien we dat je de betaling zo snel mogelijk voldoet. Heb je vragen? %KLIKHIER%',
        button: 'Details',
    },
};

export const errors: ErrorTypes = {
    internalServerError: {
        header: 'Wij kunnen uw gegevens niet tonen',
        content: 'Onze excuses. Er is een technische fout opgetreden. Probeer het later nog eens.',
    },
    serviceUnavailable: {
        header: 'Wij kunnen uw gegevens niet tonen',
        content: 'Onze excuses. Er wordt op dit moment onderhoud gepleegd aan het systeem. Probeer het later nog eens.',
    },
    default: {
        header: 'Wij kunnen uw gegevens niet tonen',
        content:
            "Sorry, er is een technische fout opgetreden. Probeer het later nog eens of neem contact op met <a href='mailto:customerservice@audifinancialservices.nl'>customer service</a>.",
    },
};

export const noContractsFoundError = {
    status: 502,
    code: 'NO_CONTRACT_FOUND_FOR_GIVEN_BRAND',
    message: 'No contracts found for given brand',
};

export const apiNotReachableError = {
    status: 502,
    code: 'MARKET_API_DEFAULT_BUSINESS_ERROR',
    message: 'The market API responded with an unexpected/ignored error code',
};

export const internalServerError = {
    status: 502,
    code: 'MARKET_API_INTERNAL_SERVER_ERROR',
    message: 'The market API responded with an error indicating a technical problem',
};

export const serviceUnavailableError = {
    status: 502,
    code: 'MARKET_API_SERVICE_UNAVAILABLE',
    message: 'The market API is currently unavailable',
};

export const ContractHandoverFormInitialValues = {
    companyID: '123456',
    companyName: 'SomeCompany',
    consentFlag: true,
    email: 'someMail@post.com',
    firstName: 'sdf',
    gender: 'Dhr.',
    initials: 'F.P.J.',
    lastName: 'sdf',
    middleName: 'sd',
    mobileNumber: '23424',
    mobileNumberCountryCode: '31',
};
