import { Contract } from 'common';
import { Accordion, AccordionItem } from '@cp-shared-7/frontend-ui';
import { FinancialDetails } from 'components/contracts/financial-details';
import { InterveningParties } from 'components/contracts/intervening-parties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { currentBrand } from 'config';

export type ContractCardBodyProps = {
    contract: Contract;
};

export const ContractCardBody: React.FC<ContractCardBodyProps> = ({ contract }) => {
    const { t: ft } = useTranslation('financial-details');
    const { t: ct } = useTranslation('contracts');

    const contractId = contract.contractNumber;

    return (
        <Accordion lazyRender={true} className={`${currentBrand === 'skoda' ? 'fix-accordion' : ''}`}>
            <AccordionItem title={ft('financial-details.headline')}>
                <FinancialDetails contract={contract} />
            </AccordionItem>
            <AccordionItem title={ct('intervening-parties.headline')}>
                <InterveningParties contractId={contractId} link={contract._links?.interveningParties} />
            </AccordionItem>
        </Accordion>
    );
};
