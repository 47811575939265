import { Contract, formatAsCurrency, getPayOffProposalPdfEndpoint, PayOffProposal, TotalEarlySettlement } from 'common';
import { formatCpDate } from '@cp-shared-7/common-utilities';
import {
    DividedContractHeader,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-7/frontend-ui';
import { ProductTable, Heading, Layout, LicensePlate, Modal, DescriptionList } from '@vwfs-bronson/bronson-react';
import base64ToBlob from 'b64-to-blob';
import { dashboardPagePath, offlinePaymentPath, totalEarlySettlementPagePath } from 'components/navigation/paths';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { CpDataApi } from 'cp-xhr';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { OfflinePayment } from '../offline-payment/OfflinePayment';
import { TodaysValidQuote } from '../todays-valid-quote/TodaysValidQuote';

type TotalEarlySettlementUiProps = {
    totalEarlySettlementCMS?: TotalEarlySettlement;
    contract?: Contract;
    payOffProposal?: PayOffProposal;
};

export const TotalEarlySettlementUi: React.FC<TotalEarlySettlementUiProps> = ({
    totalEarlySettlementCMS,
    contract,
    payOffProposal,
}) => {
    const { path } = useRouteMatch();
    const { t } = useTranslation('total-early-settlement');
    const history = useHistory();
    const { onAction } = useAnalyticsActionTracker('onEarlySettlementProceedToPayment');
    const [showErrorDialogue, setShowErrorDialogue] = useState(false);
    const [isLoadingPDF, setIsLoadingPDF] = useState(false);

    useAnalyticsPageViewTracker('earlySettlement', !!contract);

    if (!contract || !payOffProposal) {
        return <NoConnectionNotification />;
    }

    const {
        carBrand,
        carModel,
        productType,
        contractNumber,
        licensePlate,
        nextDueAmount,
        outstandingBalance,
        contractEndDate,
        encryptedContractVersionId,
    } = contract;

    const carInformation = `${carBrand || ''} ${carModel || ''}`;

    const rightSideContent = (
        <ProductTable.Section>
            <Heading level={4}>{t('divided-contract-card.section-title')}</Heading>
            <DescriptionList.Group>
                <DescriptionList.Detail termText={t('divided-contract-card.next-due-amount')}>
                    {formatAsCurrency(nextDueAmount)}
                </DescriptionList.Detail>
                <DescriptionList.Detail termText={t('divided-contract-card.outstanding-balance')}>
                    {formatAsCurrency(outstandingBalance)}
                </DescriptionList.Detail>
                <DescriptionList.Detail termText={t('divided-contract-card.contract-end-date')}>
                    {formatCpDate(contractEndDate)?.format('DD-MM-YY')}
                </DescriptionList.Detail>
            </DescriptionList.Group>
        </ProductTable.Section>
    );

    const redirectToDashboard = (): void => {
        history.push(dashboardPagePath());
    };

    const redirectToOfflinePayment = (): void => {
        history.push(offlinePaymentPath(contract.encryptedContractVersionId));
    };

    const redirectToTodaysValidQuote = (): void => {
        onAction();
        history.push(totalEarlySettlementPagePath(contract.encryptedContractVersionId));
    };

    const generatePDF = (): void => {
        setIsLoadingPDF(true);
        const link = getPayOffProposalPdfEndpoint(encryptedContractVersionId);
        !link
            ? setShowErrorDialogue(true)
            : CpDataApi.get(link, { params: { contractNumber } })
                  .then((response) => {
                      const fileContent = response.data;
                      const pdfContentType = 'application/pdf';
                      const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                      const filename = `total-early-settlement-${encryptedContractVersionId}.pdf`;
                      saveAs(pdfBlob, filename);
                  })
                  .catch(() => {
                      setShowErrorDialogue(true);
                  })
                  .finally(() => {
                      setIsLoadingPDF(false);
                  });
    };

    return (
        <>
            {isLoadingPDF && <Spinner fullPage={true} />}
            <Layout>
                <Layout.Item>
                    <DividedContractHeader
                        contractIdentifier={
                            <LicensePlate
                                size={'medium'}
                                country={'NL'}
                                countryCode={'NL'}
                                nl={true}
                                registrationNumber={licensePlate || '-'}
                                horizontalStripEu={true}
                                euStars={true}
                            />
                        }
                        carInformation={carInformation}
                        contractCategory={productType || ''}
                        contractNumber={contractNumber}
                        rightSideContent={rightSideContent}
                    />
                </Layout.Item>
                <Switch>
                    <Route path={offlinePaymentPath()}>
                        <OfflinePayment
                            totalEarlySettlementCMS={totalEarlySettlementCMS}
                            backButtonClick={redirectToTodaysValidQuote}
                            dashboardButtonClick={redirectToDashboard}
                            printButtonClick={generatePDF}
                            payOffProposal={payOffProposal}
                            contract={contract}
                        />
                    </Route>
                    <Route path={path}>
                        <TodaysValidQuote
                            payOffProposal={payOffProposal}
                            totalEarlySettlementCMS={totalEarlySettlementCMS}
                            backButtonClick={redirectToDashboard}
                            continueButtonClick={redirectToOfflinePayment}
                        />
                    </Route>
                </Switch>
            </Layout>
            <Modal
                shown={showErrorDialogue}
                status="error"
                buttonConfirmText={totalEarlySettlementCMS?.paymentDetails.downloadModal.buttonText}
                title={totalEarlySettlementCMS?.paymentDetails.downloadModal.modalText}
                onConfirm={() => setShowErrorDialogue(false)}
                onClickOutside={() => setShowErrorDialogue(false)}
                onClose={() => setShowErrorDialogue(false)}
                testId={'error-modal'}
            />
        </>
    );
};
