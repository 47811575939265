import React from 'react';
import { CompanyDetails } from 'common';
import { DataOverview, DefinitionList, DefinitionListItem } from '@cp-shared-7/frontend-ui';
import { useTranslation } from 'react-i18next';

export const CompanyDetailsSection: React.FC<{ companyDetails?: CompanyDetails }> = ({ companyDetails }) => {
    const { t } = useTranslation('my-profile');

    const companyDetailsItems: DefinitionListItem[] = [
        {
            label: t('company-details.company-name'),
            value: companyDetails?.companyName,
        },
        {
            label: t('company-details.kvk-number'),
            value: companyDetails?.kvkNumber,
        },
    ].filter((item) => item.value);

    const companyContactDetailsItems: DefinitionListItem[] = [
        {
            label: t('company-details.e-mail'),
            value: companyDetails?.businessEmail,
        },
        {
            label: t('company-details.mobile-phone'),
            value: companyDetails?.businessMobilePhone,
        },
        {
            label: t('company-details.main-phone'),
            value: companyDetails?.businessMainPhone,
        },
    ].filter((item) => item.value);

    return companyDetails ? (
        <>
            <h3 className={'u-mt'}>{t('section-headline-company-information')}</h3>
            <DataOverview title={t('company-details.sub-headline.company')}>
                <DefinitionList split={true} list={companyDetailsItems} />
            </DataOverview>
            <DataOverview title={t('company-details.sub-headline.contact')}>
                <DefinitionList split={true} list={companyContactDetailsItems} />
            </DataOverview>
        </>
    ) : null;
};
