import React, { useCallback, useEffect } from 'react';
import { Combobox, FormField } from '@vwfs-bronson/bronson-react';
import { Contract } from 'common';
import { useTranslation } from 'react-i18next';
import { ContractSelectItem } from 'components/common';
import { ComboboxItem, ComboboxState } from '../../../../types/bronson-react';
import { useField } from 'formik';
import { currentBrand } from '../../../../config';

type ContractSelectProps = {
    label: string;
    name: string;
    contracts: Contract[] | undefined;
    unpaidBalanceContract?: string;
};

export const ContractSelect: React.FC<ContractSelectProps> = ({
    label,
    contracts = [],
    name,
    unpaidBalanceContract,
}) => {
    const { t } = useTranslation('request');
    const [, , helper] = useField(name);

    const noRelatedContractOption: ComboboxItem = {
        content: <>{t(`contract-options.no-related-contract`)}</>,
        optionKey: 'noRelatedContract',
        optionValue: t(`contract-options.no-related-contract`),
        testId: 'noRelatedContract',
    };

    const contractData: ComboboxItem[] = [
        noRelatedContractOption,
        ...contracts.map((contract) => ({
            content: <ContractSelectItem contract={contract} />,
            optionKey: contract.contractNumber,
            optionValue: contract.contractNumber,
            testId: contract.contractNumber,
        })),
    ];

    const initalOption = unpaidBalanceContract ? unpaidBalanceContract : 'noRelatedContract';

    const getDefaultState = (): ComboboxState => {
        const defaultOption = contractData.filter((x) => x.optionKey === initalOption)[0] || noRelatedContractOption;
        return {
            value: [
                {
                    key: defaultOption.optionKey,
                    value: defaultOption.optionValue,
                },
            ],
        };
    };

    useEffect(() => {
        helper.setValue(getDefaultState().value[0].value);
    }, []);

    const internalOnChange = useCallback((options: { key: string; value: string }[]): void => {
        const values: string[] = options.map((option) => option.value);

        helper.setValue(values[0]);
    }, []);

    return (
        <FormField labelText={label} type="select" className={currentBrand === 'skoda' ? 'fix-combobox' : ''}>
            <Combobox testId={name} defaultState={getDefaultState()} onChange={internalOnChange}>
                {contractData.map((options) => (
                    <Combobox.Item
                        key={options.optionKey}
                        optionKey={options.optionKey}
                        optionValue={options.optionValue}
                        testId={options.optionKey}
                    >
                        {options.content}
                    </Combobox.Item>
                ))}
            </Combobox>
        </FormField>
    );
};
