import { BankDetailsSectionContentCms } from 'common';
import { BankData, getIbanValidationEndpoint, SignedData } from '@cp-shared-7/apis';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-7/common-utilities';
import { parseErrorResponse } from '@cp-shared-7/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import * as Yup from 'yup';
import { IbanValidationError } from './validated-iban-input/IbanValidationError';
import { isValidCountryCode, isValidLength, stripWhitespaces } from './validated-iban-input/ibanValidationsUtils';
import { IbanStateHandler } from './validated-iban-input/types';

export const validationSchema = (
    cmsContent: BankDetailsSectionContentCms,
    notSameName: boolean,
    setIsValidating: (isValidating: boolean) => void,
    ibanStateHandler: IbanStateHandler,
    currentIban: string,
) => {
    const { validationErrors } = cmsContent.form;
    const maxrequestTextLength = 200;
    const { savedIban, setSavedIban, setSignedBankData } = ibanStateHandler;

    const getIbanError = (errorCode: string, iban: string): Yup.ValidationError => {
        switch (errorCode) {
            case 'INCORRECT_IBAN':
                return new Yup.ValidationError(validationErrors.iban.invalid, iban, 'iban');
            default:
                return new Yup.ValidationError(validationErrors.iban.unavailableApi, iban, 'iban');
        }
    };

    const ibanValidation = Yup.string()
        .required(validationErrors.iban.required)
        .test('validCountryCode', validationErrors.iban.invalid, (iban): boolean => isValidCountryCode(iban || ''))
        .matches(RegExp('^[A-Z]{2}[0-9]{2}[a-zA-Z0-9_ ]*$'), validationErrors.iban.invalid)
        .test('ibanLength', validationErrors.iban.invalid, (iban): boolean => isValidLength(iban || ''))
        .test('sameIban', validationErrors.iban.same, (str) => stripWhitespaces(str) !== currentIban);

    return Yup.object().shape({
        iban: ibanValidation.test('asyncIban', validationErrors.iban.invalid, async (iban?: string | null) => {
            if (!ibanValidation.isValidSync(iban)) {
                setSignedBankData(undefined);
                setSavedIban({});
                return true;
            }
            if (savedIban.iban === iban) {
                if (!savedIban.error) {
                    return true;
                }
                return getIbanError(savedIban.error, savedIban.iban);
            }
            await setIsValidating(true);
            return await CpDataApi.post(getIbanValidationEndpoint(), { iban })
                .then(({ data }: { data: SignedData<BankData> }) => {
                    const { isValid } = data.data;
                    setSignedBankData(isValid ? data : undefined);
                    setSavedIban({ iban, error: isValid ? undefined : 'INCORRECT_IBAN' });
                    return isValid;
                })
                .catch((error) => {
                    const errorCode = parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationError>>(error)
                        .code;
                    setSavedIban({ iban, error: errorCode });
                    return getIbanError(errorCode, iban);
                })
                .finally(async () => {
                    await setIsValidating(false);
                });
        }),
        ibanName: notSameName ? Yup.string().trim().required(validationErrors.ibanNameRequired) : Yup.string(),
        requestText: notSameName
            ? Yup.string()
                  .trim()
                  .required(validationErrors.requestTextRequired)
                  .max(maxrequestTextLength, validationErrors.requestTextMax)
            : Yup.string(),
    });
};
