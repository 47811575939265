import { Identification } from 'common';
import { DataOverview, DefinitionList, DefinitionListItem } from '@cp-shared-7/frontend-ui';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const IdentificationSection: React.FC<{ identification?: Identification }> = ({ identification }) => {
    const { t } = useTranslation('my-profile');

    if (!identification?.surname) {
        return (
            <DataOverview title={t('identification.sub-headline')}>
                <NoConnectionNotification />
            </DataOverview>
        );
    }

    const firstName = identification?.firstName ? `(${identification?.firstName})` : '';
    const fullName = `${identification?.initials || ''} ${identification?.middleName || ''} ${
        identification?.surname
    } ${firstName}`;

    const identificationItems: DefinitionListItem[] = [
        {
            label: t('identification.name'),
            value: fullName,
        },
    ];

    return (
        <DataOverview title={t('identification.sub-headline')}>
            <DefinitionList split={true} list={identificationItems} />
        </DataOverview>
    );
};
