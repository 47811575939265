import { ContractErrorCode, FinancialDetails } from 'common';
import { DefaultMarketApiErrors } from '@cp-shared-7/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-7/frontend-integration';
import { useContract } from 'components/contracts/useContract';
import { FinancialDetailsDataSelector } from './FinancialDetailsDataSelector';
import { fetchFinancialDetails } from './FinancialDetailsSlice';

export function useFinancialDetails(
    contractId: string,
): AbstractDataState<FinancialDetails, DefaultMarketApiErrors | ContractErrorCode> {
    const contractState = useContract(contractId);
    const financialDetailsState = useGetContractBasedApiData(
        contractId,
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        contractState.data?._links?.financialDetails,
    );

    return {
        isLoading: contractState.isLoading || financialDetailsState.isLoading,
        hasReceivedResponse: contractState.loadingError
            ? contractState.hasReceivedResponse
            : contractState.hasReceivedResponse && financialDetailsState.hasReceivedResponse,
        failedLoadingAttempts: hasFetchedSuccessfully(contractState)
            ? financialDetailsState.failedLoadingAttempts
            : contractState.failedLoadingAttempts,
        data: financialDetailsState.data,
        loadingError: hasFetchedSuccessfully(contractState)
            ? financialDetailsState.loadingError
            : contractState.loadingError,
    };
}
