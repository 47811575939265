import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useVehicleImage } from './useVehicleImage';
import { DescriptionList, LicensePlate, ProductInfo } from '@vwfs-bronson/bronson-react';
import { VehicleImageView } from '@cp-shared-7/apis';
import { Brand } from 'common';
import { VehicleDetailsUi } from './vehicle-details-ui/VehicleDetailsUi';
import { TFunction } from 'i18next';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useVehicleDetails } from './useVehicleDetails';
import { capitalizeWord, getColorFinishTranslation, getColorTranslation, getTransmissionTranslation } from './utils';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { DefinitionTableItem } from './types';

const getImageFallbackUrlTranslationKey = (brand?: string): string => {
    switch (brand) {
        case Brand.AUDI:
            return 'fallback-url.audi';
        case Brand.SEAT:
            return 'fallback-url.seat';
        case Brand.SKODA:
            return 'fallback-url.skoda';
        case Brand.VW:
            return 'fallback-url.vw';
    }
    return 'fallback-url.vw';
};

const VehicleDetailsAndImageUi: React.FC<{
    vin: string;
    encryptedVin: string;
    brand?: string;
    model: string;
    licensePlate?: string;
    defaultView: VehicleImageView;
}> = ({ vin, encryptedVin, brand, model, licensePlate, defaultView }) => {
    const { t } = useTranslation('vehicle-details-and-image');
    const [title, setTitle] = useState(`${brand} ${model}`);
    const [subtitle, setSubtitle] = useState('');
    const {
        data: vehicleImages,
        isLoading: isLoadingVehicleImage,
        loadingError: vehicleImageLoadingError,
    } = useVehicleImage(encryptedVin, defaultView);
    const { data: vehicleDetails, isLoading: isLoadingVehicleDetails } = useVehicleDetails(encryptedVin);

    useEffect(() => {
        if (vehicleDetails) {
            const {
                modelName = '',
                modelVariant = '',
                transmission = '',
                bodyColorName = '',
                bodyColorFinish = '',
                trim = '',
            } = vehicleDetails;
            setTitle(`${brand} ${[modelName, modelVariant, trim].filter(Boolean).map(capitalizeWord).join(' ')}`);
            setSubtitle(
                [
                    getTransmissionTranslation(transmission, t),
                    getColorTranslation(bodyColorName, t),
                    getColorFinishTranslation(bodyColorFinish, t),
                ]
                    .filter(Boolean)
                    .map(capitalizeWord)
                    .join(' | '),
            );
        }
    }, [brand, vehicleDetails]);

    const getBasicDetails = useCallback((t: TFunction, vin: string, licensePlate?: string): DefinitionTableItem[] => {
        return compact([
            licensePlate
                ? {
                      title: t('license-plate'),
                      value: (
                          <LicensePlate
                              registrationNumber={licensePlate}
                              size={'medium'}
                              country={'NL'}
                              countryCode={'NL'}
                              nl={true}
                              horizontalStripEu={true}
                              euStars={true}
                          />
                      ),
                      testId: 'vehicle-details-license-plate',
                  }
                : undefined,
            vin
                ? {
                      title: t('vin'),
                      value: vin,
                      testId: 'vehicle-details-vin',
                  }
                : undefined,
        ]);
    }, []);

    const basicDetails: DefinitionTableItem[] = useMemo(() => getBasicDetails(t, vin, licensePlate), [
        getBasicDetails,
        t,
        vin,
        licensePlate,
    ]);

    const getImageSrc = () => {
        const image = vehicleImages?.images?.find((image) => image.view === defaultView);
        const showDefault = vehicleImageLoadingError || (!isLoadingVehicleImage && vehicleImages?.images && !image);
        if (!isLoadingVehicleImage && image) {
            return image.url;
        } else if (showDefault) {
            return t(getImageFallbackUrlTranslationKey(brand));
        }
        return '';
    };

    return (
        <ProductInfo
            title={title}
            caption={subtitle}
            className={'u-mb u-mt'}
            media={<img src={getImageSrc()} alt="" />}
        >
            <DescriptionList testId={'basic-details'}>
                <DescriptionList.Group>
                    {basicDetails.map((detail: DefinitionTableItem) => {
                        return (
                            <DescriptionList.Detail termText={detail.title} key={detail.title}>
                                {detail.value}
                            </DescriptionList.Detail>
                        );
                    })}
                </DescriptionList.Group>
            </DescriptionList>
            <VehicleDetailsUi details={vehicleDetails} isLoading={isLoadingVehicleDetails} />
        </ProductInfo>
    );
};

export const VehicleDetailsAndImage: React.FC<{
    vin?: string;
    encryptedVin?: string;
    brand?: string;
    model?: string;
    licensePlate?: string;
    defaultView?: VehicleImageView;
}> = ({ vin, encryptedVin, defaultView = VehicleImageView.EXTERIOR_FRONT_LEFT, brand, model = '', licensePlate }) => {
    if (!vin || !encryptedVin) return <NoConnectionNotification testId={'no-connection-error'} />;

    return (
        <VehicleDetailsAndImageUi
            vin={vin}
            encryptedVin={encryptedVin}
            brand={brand}
            model={model}
            licensePlate={licensePlate}
            defaultView={defaultView}
        />
    );
};
