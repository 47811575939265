import { FinancialDetails } from 'common';
import { formatCpDate } from '@cp-shared-7/common-utilities';

export const FinancialDetailsWithAllValues: FinancialDetails = {
    contractStartDate: formatCpDate('2015-11-10').toCpDate(),
    contractEndDate: formatCpDate('2030-02-01').toCpDate(),
    firstPaymentDate: formatCpDate('2015-11-10').toCpDate(),
    lastPaymentDate: formatCpDate('2030-02-01').toCpDate(),
    contractDuration: '48 Maanden',
    curbPrice: 2351.03,
    downPayment: 2351.03,
    tradeInAmount: 2351.03,
    internalRepayment: 2351.03,
    nominalAmount: 2351.03,
    creditCompensation: 2351.03,
    interest: 0.04,
    nominalInterest: 0.04,
    annualPercentageRate: 0.07,
    finalPaymentAmount: 75000,
    totalAmountToBePaid: 75000,
    monthlyDueDay: '14e van de maand',
};
