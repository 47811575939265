import { OpenVerificationFormData, OpenVerificationContent } from 'common';
import { CPDate, formatCpDate } from '@cp-shared-7/common-utilities';
import { CleaveInput, preventSubmit, ValidatedInput } from '@cp-shared-7/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { openVerificationValidationSchema } from './ValidationSchema';

type ChangeAddressProps = {
    handleSubmit: (data: OpenVerificationFormData) => void;
    openVerificationContent?: OpenVerificationContent;
};

type FormData = {
    contractNumber: string;
    licensePlate: string;
    initials: string;
    middleName?: string;
    lastName: string;
    birthday: CPDate;
};

export const OpenVerificationForm: React.FC<ChangeAddressProps> = ({ handleSubmit, openVerificationContent }) => {
    const { t } = useTranslation('open-verification');
    const content = openVerificationContent || {
        headline: '',
        description: '',
        contractNumber: '',
        contractNumberTooltip: '',
        licensePlate: '',
        initials: '',
        middleName: '',
        lastName: '',
        birthday: '',
        submit: '',
    };

    return (
        <>
            <Layout>
                <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                    <h1>{content.headline}</h1>
                </Layout.Item>
                <Layout.Item className={'u-m-xxsmall'}>
                    <span dangerouslySetInnerHTML={{ __html: content.description }} />
                </Layout.Item>
                <Layout.Item>
                    <Formik
                        initialValues={{
                            contractNumber: '',
                            licensePlate: '',
                            initials: '',
                            middleName: '',
                            lastName: '',
                            birthday: '',
                        }}
                        validationSchema={openVerificationValidationSchema(t)}
                        onSubmit={(data: FormData) =>
                            handleSubmit({
                                ...data,
                                birthday: formatCpDate(data.birthday, 'DD.MM.YYYY', true).toCpDate(),
                            })
                        }
                    >
                        {(formik) => (
                            <Form onSubmit={preventSubmit()}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ValidatedInput
                                            label={content.contractNumber}
                                            tooltip={content.contractNumberTooltip}
                                            name="contractNumber"
                                            testId="contractNumber"
                                            type="text"
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedInput
                                            label={content.licensePlate}
                                            name="licensePlate"
                                            testId="licensePlate"
                                            type="text"
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2">
                                                <CleaveInput
                                                    label={content.initials}
                                                    name="initials"
                                                    testId="initials"
                                                    type="text"
                                                    handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        formik.setFieldValue(
                                                            'initials',
                                                            e.target.value.replaceAll(/[0-9]\./g, ''),
                                                        );
                                                    }}
                                                    cleaveOptions={{
                                                        delimiter: '.',
                                                        blocks: [1, 1, 1, 1, 1, 1, 1, 1, 1],
                                                        numericOnly: false,
                                                        uppercase: true,
                                                    }}
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/2">
                                                <ValidatedInput
                                                    label={content.middleName}
                                                    name="middleName"
                                                    testId="middleName"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedInput
                                            label={content.lastName}
                                            name="lastName"
                                            testId="lastName"
                                            type="text"
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <CleaveInput
                                            cleaveOptions={{
                                                delimiter: '.',
                                                blocks: [2, 2, 4],
                                                numericOnly: true,
                                            }}
                                            inputMode={'numeric'}
                                            label={content.birthday}
                                            name={'birthday'}
                                            testId={'date-of-birth'}
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer center className="u-mt">
                                            <Button
                                                onClick={formik.submitForm}
                                                testId={'submit-open-verification-button'}
                                            >
                                                {content.submit}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </Layout.Item>
            </Layout>
        </>
    );
};
