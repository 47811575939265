import { Contract, ContractsCms, ContractStatus } from 'common';
import { formatCpDate } from '@cp-shared-7/common-utilities';
import { ExpandableContent, FormSectionGroup } from '@vwfs-bronson/bronson-react';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractCard } from './contract-card';
import { groupContractsByVin } from '../../../utils/contracts-utils';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image/VehicleDetailsAndImage';

export type ContractsOverviewProps = {
    contracts?: Contract[];
    mandatoryFieldsMissing?: boolean;
    content?: ContractsCms;
};

const compareContracts = (a: Contract, b: Contract) => {
    if (!a.contractEndDate) return 1;
    if (!b.contractEndDate) return -1;
    return formatCpDate(a.contractEndDate).toMoment().isAfter(b.contractEndDate) ? 1 : -1;
};

export const ContractsOverview: React.FC<ContractsOverviewProps> = ({ contracts, mandatoryFieldsMissing, content }) => {
    const { t } = useTranslation('contracts');

    const sortedContracts = useMemo(() => (contracts || []).concat().sort(compareContracts), [contracts]);

    const getListOfContracts = useCallback(
        (isActive: boolean) => {
            const contractStatus = isActive ? ContractStatus.ACTIVE : ContractStatus.EXPIRED;
            const filteredContracts: Contract[] = sortedContracts.filter(
                (contract: Contract) => contract.contractStatus === contractStatus,
            );
            const groupedContractsByVin = groupContractsByVin(filteredContracts);

            return groupedContractsByVin.length ? (
                <FormSectionGroup>
                    {groupedContractsByVin.map((contracts) =>
                        contracts.map((contract, contractIndex) => (
                            <React.Fragment key={contract.contractNumber}>
                                {contractIndex === 0 && (
                                    <VehicleDetailsAndImage
                                        vin={contract.vinCode}
                                        encryptedVin={contract.encryptedVinCode}
                                        brand={contract.carBrand}
                                        model={contract.carModel}
                                        licensePlate={contract.licensePlate}
                                        key={`${contract.contractNumber}-details`}
                                    />
                                )}
                                <ContractCard
                                    contract={contract}
                                    key={contract.contractNumber}
                                    content={content}
                                    defaultExpanded={isActive && contractIndex === 0}
                                />
                            </React.Fragment>
                        )),
                    )}
                </FormSectionGroup>
            ) : null;
        },
        [sortedContracts, content],
    );

    const memoizedActiveContracts = useMemo(() => getListOfContracts(true), [getListOfContracts]);

    const memoizedInactiveContracts = useMemo(() => getListOfContracts(false), [getListOfContracts]);

    if (!content) return null;

    return (
        <>
            {mandatoryFieldsMissing && (
                <div className={'u-mb'}>
                    <NoConnectionNotification testId="no-connection" />
                </div>
            )}
            {memoizedActiveContracts}
            {!!memoizedInactiveContracts && (
                <>
                    <div className={'u-mt-large'}>
                        <h4 className="u-text-center">{t('inactive-contracts')}</h4>
                    </div>
                    <ExpandableContent
                        lazyRender={true}
                        noBackground
                        pageWrapClassName={'u-pl-small u-pr-small'}
                        triggerLabel={t('click-here')}
                    >
                        {memoizedInactiveContracts}
                    </ExpandableContent>
                </>
            )}
        </>
    );
};
