import { getRegisterPageEndpoint, RegistrationEmail } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<RegistrationEmail>({
    contentName: 'registrationEmail',
    contentEndpoint: getRegisterPageEndpoint,
});

export default reducer;
export const fetchRegistrationEmail = fetchContent;
