import { getNavigationBarEndpoint, NavigationBar } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<NavigationBar>({
    contentName: 'navigationBar',
    contentEndpoint: getNavigationBarEndpoint,
});

export default reducer;
export const fetchNavigationBar = fetchContent;
