import { FinancialDetails, formatAsCurrency, formatAsDate, formatAsPercentage, FinancialDetailsCms } from 'common';
import { DefinitionListItem, DefinitionList } from '@cp-shared-7/frontend-ui';
import React from 'react';

import { useCmsContent } from 'utils/useCmsContent';

export const BaseSection: React.FC<{ financialDetails?: FinancialDetails }> = ({ financialDetails }) => {
    const [cmsContent] = useCmsContent<FinancialDetailsCms>('financial-details');

    if (!financialDetails || !cmsContent) {
        return null;
    }

    const { financialDetails: financialDetailsContent } = cmsContent;

    const financialDetailsItems: DefinitionListItem[] = [
        {
            label: financialDetailsContent.contractStartDate.label,
            value: formatAsDate(financialDetails.contractStartDate),
        },
        {
            label: financialDetailsContent.contractEndDate.label,
            value: formatAsDate(financialDetails.contractEndDate),
        },
        {
            label: financialDetailsContent.firstPaymentDate.label,
            value: formatAsDate(financialDetails.firstPaymentDate),
        },
        {
            label: financialDetailsContent.lastPaymentDate.label,
            value: formatAsDate(financialDetails.lastPaymentDate),
        },
        {
            label: financialDetailsContent.contractDuration.label,
            value: financialDetails.contractDuration,
        },
        {
            label: financialDetailsContent.curbPrice.label,
            tooltip: financialDetailsContent.curbPrice.tooltip,
            value: formatAsCurrency(financialDetails.curbPrice),
        },
        {
            label: financialDetailsContent.downPayment.label,
            tooltip: financialDetailsContent.downPayment.tooltip,
            value: formatAsCurrency(financialDetails.downPayment),
        },
        {
            label: financialDetailsContent.tradeInAmount.label,
            tooltip: financialDetailsContent.tradeInAmount.tooltip,
            value: formatAsCurrency(financialDetails.tradeInAmount),
        },
        {
            label: financialDetailsContent.internalRepayment.label,
            tooltip: financialDetailsContent.internalRepayment.tooltip,
            value: formatAsCurrency(financialDetails.internalRepayment),
        },
        {
            label: financialDetailsContent.nominalAmount.label,
            tooltip: financialDetailsContent.nominalAmount.tooltip,
            value: formatAsCurrency(financialDetails.nominalAmount),
        },
        {
            label: financialDetailsContent.creditCompensation.label,
            tooltip: financialDetailsContent.creditCompensation.tooltip,
            value: formatAsCurrency(financialDetails.creditCompensation),
        },
        {
            label: financialDetailsContent.interest.label,
            tooltip: financialDetailsContent.interest.tooltip,
            value: formatAsPercentage(financialDetails.interest && financialDetails.interest * 100),
        },
        {
            label: financialDetailsContent.nominalInterest.label,
            tooltip: financialDetailsContent.nominalInterest.tooltip,
            value: formatAsPercentage(financialDetails.nominalInterest && financialDetails.nominalInterest * 100),
        },
        {
            label: financialDetailsContent.annualPercentageRate.label,
            tooltip: financialDetailsContent.annualPercentageRate.tooltip,
            value: formatAsPercentage(
                financialDetails.annualPercentageRate && financialDetails.annualPercentageRate * 100,
            ),
        },
        {
            label: financialDetailsContent.finalPaymentAmount.label,
            tooltip: financialDetailsContent.finalPaymentAmount.tooltip,
            value: formatAsCurrency(financialDetails.finalPaymentAmount),
        },
        {
            label: financialDetailsContent.totalToBePaid.label,
            tooltip: financialDetailsContent.totalToBePaid.tooltip,
            value: formatAsCurrency(financialDetails.totalAmountToBePaid),
        },
        {
            label: financialDetailsContent.monthlyDueDay.label,
            tooltip: financialDetailsContent.monthlyDueDay.tooltip,
            value: financialDetails.monthlyDueDay,
        },
    ].filter((item) => !!item.value);

    return <DefinitionList split={true} list={financialDetailsItems} />;
};
