import { BankDetails, BankDetailsSectionContentCms } from 'common';
import { BankData, SignedData } from '@cp-shared-7/apis';
import { changeBankDetailsPath } from 'components/navigation/paths';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useCmsContent } from 'utils/useCmsContent';
import { BankDetailsView } from './bank-details-view/BankDetailsView';
import { BankDetailsEditView } from './edit-view/BankDetailsEditView';
import { IbanNotificationType, IbanStateHandler } from './edit-view/validated-iban-input/types';

export const BankDetailsSection: React.FC<{ bankDetails?: BankDetails }> = ({ bankDetails }) => {
    const [cmsContent] = useCmsContent<BankDetailsSectionContentCms>('bank-details-section-content');
    const { path } = useRouteMatch();
    const history = useHistory();

    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<{ iban?: string; error?: string }>({});
    const [notificationType, setNotificationType] = useState<IbanNotificationType>();
    const [bankData, setBankData] = useState<BankDetails | undefined>(bankDetails);

    const ibanStateHandler: IbanStateHandler = {
        signedBankData,
        setSignedBankData,
        savedIban,
        setSavedIban,
    };

    const handleEditButton = (): void => {
        history.push(changeBankDetailsPath());
    };

    const handleCancel = (): void => {
        history.push(path);
    };

    if (!bankData?.iban) return <NoConnectionNotification />;
    if (!cmsContent) return null;

    return (
        <Switch>
            <Route exact path={changeBankDetailsPath()}>
                <BankDetailsEditView
                    onCancel={handleCancel}
                    cmsContent={cmsContent}
                    ibanStateHandler={ibanStateHandler}
                    setNotificationType={setNotificationType}
                    currentIban={bankData.iban}
                    setIban={(iban: string) => setBankData({ ...bankData, iban })}
                />
            </Route>
            <Route path={path}>
                <BankDetailsView
                    cmsContent={cmsContent}
                    bankDetails={bankData}
                    onEdit={handleEditButton}
                    notificationType={notificationType}
                />
            </Route>
        </Switch>
    );
};
