import { AddressSectionContent, getAddressSectionContentEndpoint } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<AddressSectionContent>({
    contentName: 'addressSection',
    contentEndpoint: getAddressSectionContentEndpoint,
});

export default reducer;
export const fetchAddressSection = fetchContent;
