import { Contract, getPostboxDocumentDataEndpoint, PostboxCms, PostboxDocument, PostboxDocumentData } from 'common';
import { formatCpDate } from '@cp-shared-7/common-utilities';
import {
    Notification,
    NotificationStatus,
    Postbox,
    PostboxFilterProps,
    PostboxTableProps,
    Spinner,
    useAnalyticsActionTracker,
} from '@cp-shared-7/frontend-ui';
import { Modal } from '@vwfs-bronson/bronson-react';
import base64ToBlob from 'b64-to-blob';
import { CpDataApi } from 'cp-xhr';
import { saveAs as downloadFileAs } from 'file-saver';
import { uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import { getContractIdentifierFilter, getDocumentTypeFilters, getTimeFrameFilterItems } from './utils';

export type PostboxUiProps = {
    postboxDocuments: PostboxDocument[];
    contracts: Contract[];
    isDataFetchingError?: boolean;
    postboxCms?: PostboxCms;
    defaultContractId?: string;
};

type RequestStatus = {
    isPending?: boolean;
    isError?: boolean;
};

const extractDocumentType = (templateName: string): string => templateName.replace(/\s?\([0-9].+\)$/, '');

export const PostboxUi: React.FC<PostboxUiProps> = ({
    postboxDocuments,
    contracts,
    isDataFetchingError,
    postboxCms,
    defaultContractId,
}) => {
    const [requestStatus, setRequestStatus] = useState<RequestStatus>({});
    const documentTypes = useMemo(() => uniq(postboxDocuments?.map((doc) => extractDocumentType(doc.documentType))), [
        postboxDocuments,
    ]);
    const sortedDocs = useMemo(
        () =>
            postboxDocuments
                .concat()
                .sort((a, b) => (formatCpDate(a.creationDate).toMoment().isBefore(b.creationDate) ? 1 : -1)),
        [postboxDocuments],
    );

    const { onAction: onSuccess } = useAnalyticsActionTracker('onPostboxDownload');
    const { onAction: onError } = useAnalyticsActionTracker('onPostboxDownloadError');

    if (!postboxCms) return null;
    if (isDataFetchingError)
        return <Notification status={NotificationStatus.error}>{postboxCms.errors.noBeConnection}</Notification>;

    const downloadDoc = async (documentId: string, templateName: string) => {
        setRequestStatus({ isPending: true });
        CpDataApi.get(getPostboxDocumentDataEndpoint(documentId), { params: new URLSearchParams({ templateName }) })
            .then((response) => {
                const { fileName, fileContent }: PostboxDocumentData = response.data;
                downloadFileAs(base64ToBlob(fileContent, 'application/pdf'), fileName);
                onSuccess(templateName);
                setRequestStatus({ isPending: false });
            })
            .catch((err) => {
                onError(templateName, err);
                setRequestStatus({ isPending: false, isError: true });
            });
    };

    const postboxTable: PostboxTableProps = {
        overridedColumns: ['colDate', 'colSubject', 'colDownload'],
        overridedHeaders: [
            postboxCms.tableHeaders.date,
            postboxCms.tableHeaders.documentType,
            postboxCms.tableHeaders.download,
        ],
        documents: sortedDocs.map((doc) => ({
            date: formatCpDate(doc.creationDate).toMoment(),
            documentId: doc.documentId,
            documentType: extractDocumentType(doc.documentType),
            contractIdentifier: doc.encryptedContractVersionId,
            subject: doc.documentType,
            onClick: (event) => {
                event?.preventDefault();
                downloadDoc(doc.documentId, doc.documentType);
            },
        })),
        noDocumentsSelectedErrorText: sortedDocs.length
            ? postboxCms.errors.emptyForFilters
            : postboxCms.errors.noDocuments,
        dateFormatter: (day, month, year) => `${day}-${month}-${year}`,
    };

    const postboxFilters: PostboxFilterProps = {
        contractIdentifierFilter: {
            label: postboxCms.filters.contractHeadline,
            filterItems: !isDataFetchingError ? getContractIdentifierFilter(contracts) : [],
            allLabel: postboxCms.filters.contractAllLabel,
        },
        documentTypeFilter: {
            label: postboxCms.filters.documentTypeHeadline,
            filterItems: !isDataFetchingError ? getDocumentTypeFilters(documentTypes) : [],
            allLabel: postboxCms.filters.documentTypeAllLabel,
        },
        timeFrameFilter: {
            label: postboxCms.filters.timeframeHeadline,
            filterItems: !isDataFetchingError ? getTimeFrameFilterItems(postboxCms.filters.timePeriods) : [],
            allLabel: postboxCms.filters.timeFrameAllLabel,
        },
    };

    return (
        <>
            {!!requestStatus.isPending && <Spinner fullPage={true} />}
            <Postbox
                filters={postboxFilters}
                table={postboxTable}
                resetButtonText={postboxCms.filters.resetLabel}
                defaultContractIdentifier={defaultContractId}
            />
            <Modal
                shown={!!requestStatus.isError}
                status="error"
                title={postboxCms.errors.downloadDocumentError.header}
                onClose={(): void => setRequestStatus({ isError: false })}
                buttonCloseLabel={postboxCms.errors.downloadDocumentError.button}
                testId={'postbox-document-download-error'}
            >
                {postboxCms.errors.downloadDocumentError.content}
            </Modal>
        </>
    );
};
